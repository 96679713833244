import { useQuery } from '@tanstack/react-query';

import { getEventApps } from './service';

export const useEventAppsQuery = eventId =>
  useQuery({
    queryKey: ['event', eventId, 'apps'],
    queryFn: () => getEventApps(eventId),
    select: ({ data }) => data,
  });
