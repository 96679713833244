import { Alert, Form, Input as AntInput, Modal, Space } from 'antd';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { t } from 'ttag';

import { getCmsEvent as getEventFn } from 'common/services/events/api';
import { setEvent } from 'common/state/event';

import { updateCode as updateFn } from './service';

const Input = styled(AntInput)`
  margin: 10px 0;

  & > .ant-input-prefix,
  & > .ant-input-suffix {
    color: #aaa;
  }
`;

const { useForm } = Form;

const EditCodeModal = ({ organizationId, eventId, eventCode, onClose = () => {} }) => {
  const [updating, setUpdating] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const dispatch = useDispatch();

  const [error, setError] = useState(null);
  const errProps = { hasFeedback: true, validateStatus: 'error', help: error };

  const [form] = useForm();

  return (
    <Modal
      title={t`Edit Event Code`}
      open
      onOk={async () => {
        const { code } = await form.validateFields();

        try {
          setUpdating(true);
          await updateFn({ event_id: eventId, new_shortcode: code });

          const { data } = await getEventFn(organizationId, eventId);
          dispatch(setEvent(data));

          return onClose();
        } catch (err) {
          setUpdating(false);

          if (err?.status === 400) {
            return setError(
              err?.errors?.[0]?.message ?? t`Unknown error occurred while updating event code.`
            );
          }

          throw err;
        }
      }}
      onCancel={() => onClose()}
      width="700px"
      okButtonProps={{ disabled }}
      confirmLoading={updating}
    >
      <Space direction="vertical">
        {error !== null && <Alert message="Error" description={error} type="error" showIcon />}
        <Alert
          message={t`Informational Notes`}
          description={t`Changing the Event Code will cause any URL links that contain or link to the Event App to be invalid and will no longer work. If you have existing links to the Event App, please be sure to update the URL links.`}
          type="info"
          showIcon
        />
        <Form form={form} layout="horizontal" hideRequiredMark initialValues={{ code: eventCode }}>
          <Form.Item noStyle shouldUpdate>
            {({ getFieldValue, getFieldError }) => {
              if (getFieldError('code').length > 0) {
                setDisabled(true);
              } else {
                setDisabled(false);
              }

              return (
                <Form.Item
                  {...(error !== null ? errProps : {})}
                  name="code"
                  rules={[
                    {
                      required: true,
                      message: t`Event Code is required`,
                    },
                    { max: 128, message: t`Event Code should consist of maximum 128 characters` },
                    { min: 3, message: t`Event Code should consist of at least 3 characters` },
                    {
                      pattern: /^[a-zA-Z-_\d]+$/g,
                      message: t`Event Code should not have any special characters, allowed characters include alphabets, numbers, underscores and dashes`,
                    },
                    {
                      pattern: /\D/g,
                      message: t`Event Code should not consist of only numbers`,
                    },
                  ]}
                >
                  <Input
                    prefix="https://www.eventmobi.com/"
                    suffix={128 - getFieldValue('code').length}
                  />
                </Form.Item>
              );
            }}
          </Form.Item>
        </Form>
      </Space>
    </Modal>
  );
};

EditCodeModal.propTypes = {
  organizationId: PropTypes.string.isRequired,
  eventId: PropTypes.number.isRequired,
  eventCode: PropTypes.string.isRequired,
  onClose: PropTypes.func,
};

export default EditCodeModal;
