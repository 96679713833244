import { Alert } from 'antd';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { t } from 'ttag';

import useSearchParams from 'common/hooks/useSearchParams';

import { BADGES, BADGES_TEMPLATES } from '@domains/Capture/constants';

import Builder from '../Builder';
import { get as getBadgeFn } from '../service';
import { get as getBadgeTemplateFn } from '../templates-service';

import { useDesignReducer } from './useDesignReducer';

const NOT_FOUND_ERROR_CODES = ['badges_templates_not_found', 'badges_not_found'];

const CaptureDesignPreview = () => {
  const query = useSearchParams();
  const token = query.get('token');
  const { eventId, resourceType, resourceId } = useParams();
  const { state, loadReducer, finishLoading } = useDesignReducer();
  const [error, setError] = useState();

  useEffect(() => {
    async function effect() {
      try {
        let payload;
        if (resourceType === BADGES) {
          payload = await getBadgeFn(eventId, resourceId, token);
        } else if (resourceType === BADGES_TEMPLATES) {
          payload = await getBadgeTemplateFn(resourceId, token);
        } else {
          throw new Error('Unknown resource type');
        }

        loadReducer(payload);
      } catch (err) {
        const notFound = NOT_FOUND_ERROR_CODES.includes(err?.errors?.[0]?.code);
        setError(notFound ? t`Resource was not found.` : t`Unknown error occurred.`);
        finishLoading();
      }
    }

    effect();
  }, [eventId, resourceType, resourceId, token, loadReducer, finishLoading]);

  if (error) {
    return <Alert type="error" message={error} showIcon />;
  }

  return <Builder state={state} readonly />;
};

export default CaptureDesignPreview;
