import { useMutation, useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';

import { getPerson as getPersonFn } from './people-service';
import { create as createFn, getLabels as getLabelsFn } from './service';

const labelsQuery = {
  getQueryKey: (eventId, peopleEntityIds = null) => ['event', eventId, 'labels', peopleEntityIds],
  queryFn: ({ queryKey: [, eventId, , peopleEntityIds] }) => getLabelsFn(eventId, peopleEntityIds),
};

export const useLabelsQuery = (peopleEntityIds = null) => {
  const { eventId } = useParams();

  return useQuery({
    queryKey: labelsQuery.getQueryKey(eventId, peopleEntityIds),
    queryFn: labelsQuery.queryFn,
  });
};

const createBadgeMutationOptions = eventId => ({
  mutationFn: payload => createFn(eventId, payload),
});

export const useCreateBadgeMutation = () => {
  const { eventId } = useParams();

  return useMutation(createBadgeMutationOptions(eventId));
};

export const usePersonQuery = (eventId, personId, token, enabled = true) =>
  useQuery({
    queryKey: ['event', eventId, 'person', personId],
    queryFn: () => getPersonFn(eventId, personId, token),
    enabled,
  });
