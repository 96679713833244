export const MOCK_PERSON_DATA_FOR_TESTING = {
  id: '70d7436c-395e-4363-b76d-0abef63dfad1',
  externalId: 'fd093a77-15da-4865-945c-0349f70f64a8',
  email: 'johndoe@eventmobi.com',
  firstName: 'John',
  lastName: 'Doe',
  companyName: 'EventMobi',
  title: 'Software Engineer',
  checkinCode: '70d7436c-395e-4363-b76d-0abef63dfad1',
  customFields: [],
};
