import { PlusOutlined, StarOutlined } from '@ant-design/icons';
import {
  Table as AntTable,
  Button,
  Divider,
  Row,
  Col,
  Breadcrumb,
  Space,
  Typography,
  Tabs as AntTabs,
  Tooltip as AntTooltip,
} from 'antd';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams, Link, useHistory } from 'react-router-dom';
import { createSelector } from 'reselect';
import styled from 'styled-components';
import { t, ngettext, msgid } from 'ttag';

import DeleteBar from 'common/components/DeleteBar';
import Empty from 'common/components/Empty';
import Loading from 'common/components/Loading';
import DeleteModal from 'common/components/Modal/DeleteModal';
import { getId } from 'common/state/organization';
import { getIsStaff } from 'common/state/user';

import usePermission, { MODIFY_MULTI_EVENT_APP_EVENTS } from '@hooks/usePermission';
import useTab from '@hooks/useTab';
import EmptyImage from '@images/illustrations/events.svg';

import { list as listFn, remove as removeFn } from './appevent-service';
import Builds from './Builds';
import Configuration from './Configuration';
import Modal from './EventModal';
import { get as getAppFn } from './service';

const { Column } = AntTable;
const { Title: AntTitle, Text } = Typography;
const Base = styled.div`
  padding: 10px 0;

  /* accommodate deleteBar component's height */
  margin-bottom: 40px;
`;
const Title = styled(AntTitle)`
  padding: 5px 5px 0 0;
  margin-left: 45px;
  && {
    font-weight: 400;
  }
`;
const Subtitle = styled.div`
  padding-bottom: 22px;
  margin-left: 45px;
  min-height: 54px;
`;

const Tabs = styled(AntTabs)`
  .ant-tabs-content-holder {
    padding: 10px 45px;
  }
`;

const Tooltip = styled(AntTooltip)`
  padding-left: 0.5rem;
`;

const DATE_FORMAT = 'MM/DD/YYYY';
const COLOR_STAFF = '#f5222d';

export const TAB_EVENTS = 'events';
export const TAB_CONF = 'conf';
export const TAB_BUILD = 'builds';
const TAB_DEFAULT = TAB_EVENTS;
const VALID_TABS = [TAB_EVENTS, TAB_CONF, TAB_BUILD];

const Table = ({ dataSource, onRowSelection, loading, selectedRowKeys, onEdit, onDelete }) => {
  const { authorized } = usePermission();
  const HIDDEN = t`Hidden`;
  const VISIBLE = t`Visible`;

  const sorter = (row1, row2, fieldName) => {
    return row1[fieldName]?.localeCompare(row2[fieldName]);
  };

  const rowSelection = {
    columnWidth: 50,
    selectedRowKeys,
    onChange: onRowSelection,
  };

  return (
    <AntTable
      rowKey="id"
      pagination={false}
      dataSource={dataSource}
      rowSelection={authorized(MODIFY_MULTI_EVENT_APP_EVENTS) && rowSelection}
      rowClassName={record => (record.isPrivate ? 'hidden-row' : 'clickable-row')}
      tableLayout="auto"
      loading={loading}
      onRow={record => ({
        onClick: () => authorized(MODIFY_MULTI_EVENT_APP_EVENTS) && onEdit(record),
      })}
    >
      <Column
        title={t`Name`}
        dataIndex="name"
        render={(name, record) => (
          <span>
            <span>{name}</span>
            {record.isFeatured && (
              <Tooltip title={t`Featured Event`} placement="top">
                <StarOutlined />
              </Tooltip>
            )}
          </span>
        )}
        sorter={(a, b) => sorter(a, b, 'name')}
        defaultSortOrder="ascend"
      />
      <Column title={t`Code`} dataIndex="shortcode" sorter={(a, b) => sorter(a, b, 'shortcode')} />
      <Column
        title={t`Duration`}
        dataIndex="duration"
        render={(_, record) =>
          `${moment.tz(record.startDate, record.timezone).format(DATE_FORMAT)} - ${moment
            .tz(record.endDate, record.timezone)
            .format(DATE_FORMAT)}`
        }
      />
      <Column
        title={t`Visibility`}
        dataIndex="isPrivate"
        filters={[
          { text: HIDDEN, value: true },
          { text: VISIBLE, value: false },
        ]}
        onFilter={(value, record) => record.isPrivate === value}
        render={value => (value ? HIDDEN : VISIBLE)}
      />
      {authorized(MODIFY_MULTI_EVENT_APP_EVENTS) && (
        <Column
          title={t`Action`}
          dataIndex="action"
          width="15%"
          onCell={() => ({ onClick: event => event.stopPropagation() })}
          render={(_, row) => {
            return (
              <Space size="small" split={<Divider type="vertical" />}>
                <Button
                  type="link"
                  onClick={() => onEdit(row)}
                  tabIndex="-1"
                  style={{ padding: 0 }}
                >
                  {t`Edit`}
                </Button>
                <Button
                  type="link"
                  danger
                  onClick={() => onDelete(row.id, row)}
                  tabIndex="0"
                  style={{ padding: 0 }}
                >
                  {t`Delete`}
                </Button>
              </Space>
            );
          }}
        />
      )}
    </AntTable>
  );
};

Table.propTypes = {
  dataSource: PropTypes.arrayOf(Object).isRequired,
  selectedRowKeys: PropTypes.arrayOf(PropTypes.string),
  loading: PropTypes.bool,
  onRowSelection: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};

const selector = createSelector(getId, getIsStaff, (organizationId, isStaff) => ({
  organizationId,
  isStaff,
}));

const useDetails = (organizationId, appId) => {
  const [appInfo, setAppInfo] = useState({});
  const [eventList, setEventList] = useState([]);
  const [loading, setLoading] = useState(false);

  const load = useCallback(async () => {
    try {
      setLoading(true);
      const [app, list] = await Promise.all([
        getAppFn(organizationId, appId),
        listFn(organizationId, appId),
      ]);
      setAppInfo(app);
      setEventList(list);
    } finally {
      setLoading(false);
    }
  }, [organizationId, appId]);

  useEffect(() => {
    if (organizationId && appId) {
      load();
    }
  }, [organizationId, appId, load]);

  return {
    appInfo,
    eventList,
    loading,
    forceReload: load,
  };
};

const Details = () => {
  const { organizationId, isStaff } = useSelector(selector);
  const { appId } = useParams();
  const [selectedRows, setSelectedRows] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [appEvent, setAppEvent] = useState(null);
  const { appInfo, eventList, loading, forceReload } = useDetails(organizationId, appId);
  const [activeTab, onChange] = useTab(TAB_DEFAULT, VALID_TABS);
  const history = useHistory();
  const { authorized } = usePermission();

  const handleNew = () => {
    setAppEvent(null);
    setShowModal(true);
  };

  const handleEdit = record => {
    setAppEvent({ ...record });
    setShowModal(true);
  };

  const handleDelete = keys => {
    const { name } = eventList.find(event => event.id === keys[0]);
    DeleteModal.confirm({
      label: {
        singular: t`Event`,
        plural: t`Events`,
      },
      itemName: name,
      count: keys.length,
      async onDelete() {
        await Promise.all(keys.map(key => removeFn(organizationId, appId, key)));

        forceReload();
        setSelectedRows([]);
      },
    });
  };

  const handleModalClose = reload => {
    setShowModal(false);
    if (reload) {
      forceReload();
    }
  };

  const handleTabChange = name => {
    onChange(name);

    if (name === TAB_CONF) {
      history.replace({ ...history.location, state: null });
    }
  };

  const EventsList = () => (
    <>
      <Row type="flex" css="margin: 20px 0;">
        {authorized(MODIFY_MULTI_EVENT_APP_EVENTS) && (
          <Col span={8}>
            <Button key="add" type="primary" icon={<PlusOutlined />} onClick={handleNew}>
              {t`Add Event`}
            </Button>
          </Col>
        )}
      </Row>
      {eventList?.length === 0 ? (
        <Empty
          image={EmptyImage}
          title={t`You don't have any events added`}
          body={t`When your app is launched, users will land on and see event list. Add events to be seen on the even list.`}
        />
      ) : (
        <Table
          dataSource={eventList}
          loading={loading}
          onRowSelection={keys => setSelectedRows([...keys])}
          selectedRowKeys={selectedRows}
          onEdit={handleEdit}
          onDelete={key => {
            handleDelete([key]);
          }}
        />
      )}
      <DeleteBar
        style={{ left: 0 }}
        open={selectedRows.length > 0}
        buttonText={ngettext(
          msgid`Delete ${selectedRows.length} Event`,
          `Delete ${selectedRows.length} Events`,
          selectedRows.length
        )}
        onClick={() => {
          handleDelete(selectedRows);
        }}
      />

      {showModal && (
        <Modal
          organizationId={organizationId}
          appId={appId}
          appEvent={appEvent}
          onClose={handleModalClose}
          skipEventIds={eventList.map(x => x.eventId)}
        />
      )}
    </>
  );

  return (
    <Base>
      {(loading || !organizationId) && <Loading />}

      {!loading && organizationId && (
        <>
          <Breadcrumb style={{ paddingTop: 18, paddingLeft: 45 }}>
            <Breadcrumb.Item>
              <Link to={`/organization/${organizationId}/apps`}>{t`Apps`}</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>{appInfo.name}</Breadcrumb.Item>
          </Breadcrumb>

          <Title>{appInfo.name}</Title>
          <Subtitle>
            {ngettext(
              msgid`Includes ${eventList.length} event`,
              `Includes ${eventList.length} events`,
              eventList.length
            )}
          </Subtitle>

          <Tabs
            type="card"
            activeKey={activeTab}
            onChange={handleTabChange}
            defaultActiveKey={TAB_EVENTS}
            style={{ overflow: 'visible' }}
            tabBarStyle={{ marginBottom: 0, paddingLeft: 45 }}
            items={[
              {
                label: t`Events`,
                key: TAB_EVENTS,
                children: <EventsList />,
              },
              isStaff && {
                label: <Text style={{ color: COLOR_STAFF }}>{t`Build Configuration`}</Text>,
                key: TAB_CONF,
                children: <Configuration organizationId={organizationId} appId={appId} />,
              },
              isStaff && {
                label: <Text style={{ color: COLOR_STAFF }}>{t`Builds`}</Text>,
                key: TAB_BUILD,
                children: <Builds organizationId={organizationId} appId={appId} />,
              },
            ].filter(Boolean)}
          />
        </>
      )}
    </Base>
  );
};

export default Details;
