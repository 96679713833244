import { useCallback } from 'react';
import { generatePath, useParams } from 'react-router-dom';

export const NAVIGATION_ITEMS = {
  EVENT_DETAILS: 'EVENT_DETAILS',
  PRIVACY_AND_TERMS: 'PRIVACY_AND_TERMS',
  PEOPLE: 'PEOPLE',
  SESSIONS: 'SESSIONS',
  COMPANIES: 'COMPANIES',
  DOCUMENTS: 'DOCUMENTS',
  VIDEOS: 'VIDEOS',
  MAPS: 'MAPS',
  SURVEYS: 'SURVEYS',
  WIDGET_PAGES: 'WIDGET_PAGES',
  EVENT_APP: 'EVENT_APP',
  EVENT_APP_HOME_PAGE: 'EVENT_APP_HOME_PAGE',
  EVENT_APP_SECTIONS: 'EVENT_APP_SECTIONS',
  EVENT_APP_GROUP_DISCUSSIONS: 'EVENT_APP_GROUP_DISCUSSIONS',
  EVENT_APP_DESIGN_STUDIO: 'EVENT_APP_DESIGN_STUDIO',
  EVENT_APP_ACTIVITY_FEED: 'EVENT_APP_ACTIVITY_FEED',
  EVENT_APP_BANNERS: 'EVENT_APP_BANNERS',
  EVENT_APP_SETTINGS: 'EVENT_APP_SETTINGS',
  BADGE_DESIGNER: 'BADGE_DESIGNER',
  GAMIFICATION: 'GAMIFICATION',
  AUDIENCE_RESPONSE: 'AUDIENCE_RESPONSE',
  LIVE_DISPLAY: 'LIVE_DISPLAY',
  ANNOUNCEMENTS: 'ANNOUNCEMENTS',
  ONSITE: 'ONSITE',
  ONSITE_CHECK_IN: 'ONSITE_CHECK_IN',
  ONSITE_LEAD_CAPTURE: 'ONSITE_LEAD_CAPTURE',
  INTEGRATIONS: 'INTEGRATIONS',
  ANALYTICS: 'ANALYTICS',
  AUDIT_LOG: 'AUDIT_LOG',
  EVENT_CONFIGURATION: 'EVENT_CONFIGURATION',
  COMMUNICATIONS: 'EVENT_COMMUNICATIONS',
  COMMUNICATIONS_EMAILS: 'COMMUNICATIONS_EMAILS',
  REGISTRATION: 'REGISTRATION',
  REGISTRATION_DETAILS: 'REGISTRATION_DETAILS',
  REGISTRATION_EMAILS: 'REGISTRATION_EMAILS',
  REGISTRATION_ORDERS: 'REGISTRATION_ORDERS',
  REGISTRATION_REG_FORM: 'REGISTRATION_REG_FORM',
  REGISTRATION_PROMO_CODES: 'REGISTRATION_PROMO_CODES',
  REGISTRATION_TICKETS: 'REGISTRATION_TICKETS',
  WEBSITE_BUILDER: 'WEBSITE_BUILDER',
  DASHBOARD: 'DASHBOARD',
};

const organizationPath = `/organization/:organizationId`;
const basepath = `${organizationPath}/event/:eventId`;

export const NAVIGATION_ROUTES = {
  [NAVIGATION_ITEMS.DASHBOARD]: {
    path: basepath,
    exact: true,
  },
  [NAVIGATION_ITEMS.EVENT_DETAILS]: {
    path: [`${basepath}/details`, `${basepath}/pages/details`],
    exact: true,
  },
  [NAVIGATION_ITEMS.PRIVACY_AND_TERMS]: {
    path: `${basepath}/privacy`,
    exact: true,
  },
  [NAVIGATION_ITEMS.PEOPLE]: {
    path: [`${basepath}/people`, `${basepath}/pages/people`],
    exact: false,
  },
  [NAVIGATION_ITEMS.SESSIONS]: {
    path: [`${basepath}/sessions`, `${basepath}/pages/sessions`],
    exact: false,
  },
  [NAVIGATION_ITEMS.COMPANIES]: {
    path: [`${basepath}/companies`, `${basepath}/pages/companies`],
    exact: false,
  },
  [NAVIGATION_ITEMS.DOCUMENTS]: {
    path: [`${basepath}/documents`, `${basepath}/pages/documents`],
    exact: true,
  },
  [NAVIGATION_ITEMS.VIDEOS]: {
    path: [`${basepath}/videos`, `${basepath}/pages/videos`],
    exact: false,
  },
  [NAVIGATION_ITEMS.MAPS]: {
    path: [`${basepath}/maps`, `${basepath}/image-map`],
    exact: false,
  },
  [NAVIGATION_ITEMS.SURVEYS]: {
    path: [`${basepath}/surveys`, `${basepath}/pages/surveys`],
    exact: false,
  },
  [NAVIGATION_ITEMS.WIDGET_PAGES]: {
    path: `${basepath}/designer-pages`,
    exact: false,
  },
  [NAVIGATION_ITEMS.EVENT_APP_HOME_PAGE]: {
    path: `${basepath}/event-app/home-page`,
    exact: true,
  },
  [NAVIGATION_ITEMS.EVENT_APP_SECTIONS]: {
    path: `${basepath}/event-app/sections`,
    exact: true,
  },
  [NAVIGATION_ITEMS.EVENT_APP_GROUP_DISCUSSIONS]: {
    path: `${basepath}/event-app/discussions`,
    exact: true,
  },
  [NAVIGATION_ITEMS.EVENT_APP_DESIGN_STUDIO]: {
    path: `${basepath}/event-app/studio`,
    exact: true,
  },
  [NAVIGATION_ITEMS.EVENT_APP_ACTIVITY_FEED]: {
    path: `${basepath}/event-app/activity-feed`,
    exact: true,
  },
  [NAVIGATION_ITEMS.EVENT_APP_BANNERS]: {
    path: `${basepath}/event-app/banner-ads`,
    exact: true,
  },
  [NAVIGATION_ITEMS.EVENT_APP_SETTINGS]: {
    path: `${basepath}/event-app/settings`,
    exact: true,
  },
  [NAVIGATION_ITEMS.BADGE_DESIGNER]: {
    path: `${basepath}/badge-designer`,
    exact: false,
  },
  [NAVIGATION_ITEMS.GAMIFICATION]: {
    path: `${basepath}/gamification`,
    exact: true,
  },
  [NAVIGATION_ITEMS.AUDIENCE_RESPONSE]: {
    path: `${basepath}/ars/settings`,
    exact: true,
  },
  [NAVIGATION_ITEMS.LIVE_DISPLAY]: {
    path: [`${basepath}/live-display`, `${basepath}/pages/live-display`],
    exact: false,
  },
  [NAVIGATION_ITEMS.ONSITE_CHECK_IN]: {
    path: `${basepath}/onsite/check-in`,
    exact: true,
  },
  [NAVIGATION_ITEMS.ONSITE]: {
    path: `${basepath}/onsite`,
    exact: true,
  },
  [NAVIGATION_ITEMS.ONSITE_LEAD_CAPTURE]: {
    path: `${basepath}/onsite/lead-capture`,
    exact: true,
  },
  [NAVIGATION_ITEMS.COMMUNICATIONS_EMAILS]: {
    path: `${basepath}/communications/emails`,
    exact: false,
  },
  [NAVIGATION_ITEMS.INTEGRATIONS]: {
    path: [`${basepath}/integrations`, `${basepath}/pages/integrations`],
    exact: true,
  },
  [NAVIGATION_ITEMS.ANNOUNCEMENTS]: {
    path: `${basepath}/communications/announcements`,
    exact: true,
  },
  [NAVIGATION_ITEMS.ANALYTICS]: {
    path: `${basepath}/analytics`,
    exact: true,
  },
  [NAVIGATION_ITEMS.AUDIT_LOG]: {
    path: `${basepath}/audit-log`,
    exact: true,
  },
  [NAVIGATION_ITEMS.EVENT_CONFIGURATION]: {
    path: `${basepath}/configuration`,
    exact: true,
  },
  [NAVIGATION_ITEMS.REGISTRATION_DETAILS]: {
    path: `${basepath}/registration/details`,
    exact: true,
  },
  [NAVIGATION_ITEMS.REGISTRATION_EMAILS]: {
    path: `${basepath}/registration/email`,
    exact: true,
  },
  [NAVIGATION_ITEMS.REGISTRATION_ORDERS]: {
    path: `${basepath}/registration/orders`,
    exact: false,
  },
  [NAVIGATION_ITEMS.REGISTRATION_PROMO_CODES]: {
    path: `${basepath}/registration/promo-codes`,
    exact: true,
  },
  [NAVIGATION_ITEMS.REGISTRATION_REG_FORM]: {
    path: `${basepath}/registration/form`,
    exact: true,
  },
  [NAVIGATION_ITEMS.REGISTRATION_TICKETS]: {
    path: `${basepath}/registration/tickets`,
    exact: true,
  },
  [NAVIGATION_ITEMS.WEBSITE_BUILDER]: {
    path: `${basepath}/website-builder`,
    exact: false,
  },
};

/**
 * TODO: Remove this comment with FF is_product_modularization_enabled
 *
 * Should only be used if the FF is on. The navigation items here have to match
 * the paths in Routes, and a couple of them changed with the FF.
 */
export const useGetNavigationPath = () => {
  const { organizationId, eventId } = useParams();

  return useCallback(
    key => {
      const firstPath = Array.isArray(NAVIGATION_ROUTES[key].path)
        ? NAVIGATION_ROUTES[key].path[0]
        : NAVIGATION_ROUTES[key].path;
      return generatePath(firstPath, { organizationId, eventId });
    },
    [organizationId, eventId]
  );
};
