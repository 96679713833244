import { useQuery } from '@tanstack/react-query';

import { prepareDataForBleed } from '../designer-service';

import { getSelectedLabels as getSelectedLabelsFn } from './service';

export const useSelectedLabelsQuery = (eventId, token) =>
  useQuery({
    queryKey: ['event', eventId, 'labels', 'selected'],
    queryFn: () => getSelectedLabelsFn(eventId, token),
    // We have to prepareDataForBleed to ensure `innerBadgeSize` is set, even though labels can't
    // have bleed enabled
    select: response => response.data.map(prepareDataForBleed),
  });
