/* global LINKED_APPLICATIONS_EVENTAPP, LINKED_APPLICATIONS_REGISTER, LINKED_APPLICATIONS_LIVEDISPLAY  */

import {
  AndroidOutlined,
  AppleOutlined,
  DesktopOutlined,
  EyeOutlined,
  FundProjectionScreenOutlined,
  LayoutOutlined,
  ProfileOutlined,
} from '@ant-design/icons';
import { Button as AntButton, Dropdown } from 'antd';
import { addDays, differenceInDays, format, isPast } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import React from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { msgid, ngettext, t } from 'ttag';

import useConfig from 'common/hooks/useConfig';
import { useEventQuery } from 'common/services/events';

import EventAppIcon from '@assets/app-icons/event-app.svg';
import LeadCaptureAppIcon from '@assets/app-icons/lead-capture.svg';
import OnsiteAppIcon from '@assets/app-icons/onsite.svg';
import { useEventAppsQuery } from '@domains/Organization/App/queries';
import { useEventDomain } from '@domains/Organization/CustomDomain/queries';
import { useSettingsQuery } from '@domains/WebsiteBuilder/ContentPages/queries';

import Item from './Item';

const Button = styled(AntButton)`
  margin-left: 12px;
  color: #1890ff;
  border-color: #1890ff;
`;

const PreviewItem = styled(Item)`
  width: 430px;
`;

export const useItems = () => {
  const { eventId, organizationId } = useParams();
  const config = useConfig();
  const isEventAppEnabled = config.isEnabled('is_eventspace_enabled');
  const isWebsiteBuilderEnabled = config.isEnabled('is_website_builder_enabled');
  const isRegistrationEnabled = config.isEnabled('is_registration_v2_enabled');
  const isLiveDisplayEnabled = config.isEnabled('is_livedisplay_enabled');
  const isCheckInEnabled = config.isEnabled('is_checkin_enabled');
  const isLeadCaptureEnabled = config.isEnabled('is_lead_capture_enabled');
  const eventappPostEventAccessDays = Number(config.get('eventapp_post_event_access_days')) ?? 0;

  const { data: event, isLoading: isLoadingEvent } = useEventQuery(eventId);
  const { data: apps, isLoading: isLoadingApps } = useEventAppsQuery(eventId);
  const { data: settings, isLoading: isLoadingSettings } = useSettingsQuery();
  const { data: customDomain, isLoading: isLoadingCustomDomain } = useEventDomain(
    organizationId,
    eventId
  );

  const loading = isLoadingSettings || isLoadingCustomDomain || isLoadingEvent || isLoadingApps;

  if (loading) {
    return { items: [] };
  }

  const {
    data: { code: eventCode, endDate: eventEndDate },
  } = event;
  const timezone = config.get('timezone');
  const localeventEndDate = utcToZonedTime(new Date(eventEndDate), timezone);
  const localEventAppEndDate = addDays(localeventEndDate, eventappPostEventAccessDays);
  const formattedEventAppEndDate = format(localEventAppEndDate, 'PPP');
  const isEventAppAccessible = !isPast(localEventAppEndDate);
  const isOnsiteAppAccessible = !isPast(localeventEndDate);
  const daysLeftForEventApp = differenceInDays(localEventAppEndDate, new Date());
  const daysLeftForOnsiteApp = differenceInDays(localeventEndDate, new Date());

  const shouldShowMobileSection =
    (isEventAppEnabled && isEventAppAccessible) ||
    (isCheckInEnabled && isOnsiteAppAccessible) ||
    (isLeadCaptureEnabled && isOnsiteAppAccessible);

  const shouldShowWebSection =
    (isEventAppEnabled && isEventAppAccessible) ||
    isWebsiteBuilderEnabled ||
    isRegistrationEnabled ||
    isLiveDisplayEnabled;

  const eventAppAccessExpiryMessage = ngettext(
    msgid`Access Expires in ${daysLeftForEventApp} day`,
    `Access Expires in ${daysLeftForEventApp} days`,
    daysLeftForEventApp
  );

  const onsiteAppAccessExpiryMessage = ngettext(
    msgid`Access Expires in ${daysLeftForOnsiteApp} day`,
    `Access Expires in ${daysLeftForOnsiteApp} days`,
    daysLeftForOnsiteApp
  );

  const items = [];

  if (shouldShowWebSection) {
    items.push({
      key: 'web-apps',
      label: t`Web Platforms`,
      type: 'group',
      style: { padding: '8px' },
    });
  }

  if (isEventAppEnabled) {
    const link = customDomain ? customDomain.domainName : LINKED_APPLICATIONS_EVENTAPP;
    items.push(
      { type: 'divider' },
      {
        key: 'event-app-web',
        label: (
          <PreviewItem
            title={t`Event App (Web Version)`}
            description={t`Attendance access to event app expires on ${formattedEventAppEndDate}`}
            links={{
              label: `${link}/${eventCode}`,
              url: `https://${link}/${eventCode}`,
            }}
            icon={<LayoutOutlined />}
          />
        ),
      }
    );
  }

  if (isWebsiteBuilderEnabled) {
    const link = customDomain ? `${customDomain.domainName}/website` : LINKED_APPLICATIONS_REGISTER;
    items.push(
      { type: 'divider' },
      {
        key: 'website',
        label: (
          <PreviewItem
            title={t`Website Landing Page`}
            description={settings.isPublished ? t`Published` : t`Unpublished`}
            links={{
              label: `${link}/${eventCode}`,
              url: `https://${link}/${eventCode}`,
            }}
            icon={<DesktopOutlined />}
          />
        ),
      }
    );
  }

  if (isRegistrationEnabled) {
    const link = customDomain ? `${customDomain.domainName}/website` : LINKED_APPLICATIONS_REGISTER;
    items.push(
      { type: 'divider' },
      {
        key: 'registration',
        label: (
          <PreviewItem
            title={t`Registration Form (Preview)`}
            links={{
              label: `${link}/${eventCode}`,
              url: `https://${link}/${eventCode}`,
            }}
            icon={<ProfileOutlined />}
            tooltip={t`Preview of your registration form`}
          />
        ),
      }
    );
  }

  if (isLiveDisplayEnabled) {
    items.push(
      { type: 'divider' },
      {
        key: 'live-display',
        label: (
          <PreviewItem
            title={t`Live Display`}
            links={{
              label: `${LINKED_APPLICATIONS_LIVEDISPLAY}/${eventCode}`,
              url: `https://${LINKED_APPLICATIONS_LIVEDISPLAY}/${eventCode}`,
            }}
            icon={<FundProjectionScreenOutlined />}
          />
        ),
      }
    );
  }

  if (shouldShowMobileSection) {
    items.push(
      { type: 'divider' },
      {
        key: 'mobile-apps',
        label: t`Mobile Applications`,
        type: 'group',
        style: { padding: '8px' },
      }
    );
  }

  if (isCheckInEnabled && isOnsiteAppAccessible) {
    items.push(
      { type: 'divider' },
      {
        key: 'onsite-app',
        label: (
          <PreviewItem
            title={t`Onsite App`}
            description={onsiteAppAccessExpiryMessage}
            links={[
              {
                label: t`Download iOS`,
                url: 'https://apps.apple.com/us/app/onsite-by-eventmobi/id1617146323',
                icon: <AppleOutlined />,
              },
              {
                label: t`Download Android`,
                url: 'https://play.google.com/store/apps/details?id=com.eventmobi.onsite',
                icon: <AndroidOutlined />,
              },
            ]}
            imageUri={OnsiteAppIcon}
            tooltip={t`Onsite app allows event organizers to check-in attendees during an event`}
            canCopyLink={false}
          />
        ),
      }
    );
  }

  if (isLeadCaptureEnabled && isOnsiteAppAccessible) {
    items.push(
      { type: 'divider' },
      {
        key: 'lead-capture-app',
        label: (
          <PreviewItem
            title={t`Lead Capture App`}
            description={onsiteAppAccessExpiryMessage}
            links={[
              {
                label: t`Download iOS`,
                url: 'https://apps.apple.com/us/app/lead-capture-by-eventmobi/id6461824837',
                icon: <AppleOutlined />,
              },
              {
                label: t`Download Android`,
                url: 'https://play.google.com/store/apps/details?id=com.eventmobi.leadcapture',
                icon: <AndroidOutlined />,
              },
            ]}
            imageUri={LeadCaptureAppIcon}
            tooltip={t`Take full control of the lead capture process with EventMobi Lead Capture`}
            canCopyLink={false}
          />
        ),
      }
    );
  }

  if (isEventAppEnabled && isEventAppAccessible) {
    if (apps?.length > 0) {
      apps.forEach(app =>
        items.push(
          { type: 'divider' },
          {
            key: app.id,
            label: (
              <PreviewItem
                title={app.appNameInStores}
                description={eventAppAccessExpiryMessage}
                links={[
                  app.iosStoreUrl && {
                    label: t`Download iOS`,
                    url: app.iosStoreUrl,
                    icon: <AppleOutlined />,
                  },
                  app.androidStoreUrl && {
                    label: t`Download Android`,
                    url: app.androidStoreUrl,
                    icon: <AndroidOutlined />,
                  },
                ].filter(Boolean)}
                imageUri={app.iconImage.thumbnailUrl}
                tooltip={t`Mobile version of your Event App`}
                canCopyLink={false}
              />
            ),
          }
        )
      );
    } else {
      items.push(
        { type: 'divider' },
        {
          key: 'event-app-mobile',
          label: (
            <PreviewItem
              title={t`Event App`}
              description={eventAppAccessExpiryMessage}
              links={[
                {
                  label: t`Download iOS`,
                  url: 'https://apps.apple.com/us/app/event-app-by-eventmobi/id1276348688',
                  icon: <AppleOutlined />,
                },
                {
                  label: t`Download Android`,
                  url:
                    'https://play.google.com/store/apps/details?id=com.eventmobi.multieventapp.meaidfa33d52eb4374fdb891ab905fa3a72e7',
                  icon: <AndroidOutlined />,
                },
              ]}
              imageUri={EventAppIcon}
              tooltip={t`Mobile version of your Event App`}
              canCopyLink={false}
            />
          ),
        }
      );
    }
  }

  return { items };
};

const PreviewButton = () => {
  const { items } = useItems();

  return (
    <Dropdown menu={{ items }} placement="bottomLeft">
      <Button icon={<EyeOutlined />}>{t`Preview`}</Button>
    </Dropdown>
  );
};

export default PreviewButton;
