import { t } from 'ttag';

import { NAVIGATION_ITEMS } from '@domains/Event/navigation-items';

export const PREMIUM_PRODUCT_KEYS = {
  BadgeDesigner: 'badge_designer_enabled',
  LeadCapture: 'is_lead_capture_enabled',
  OnsiteApp: 'is_checkin_enabled',
  Registration: 'is_registration_v2_enabled',
  EventApp: 'is_eventspace_enabled',
  Communications: 'is_communications_enabled',
  SocialAnalytics: 'is_social_monitoring_enabled',
  Website: 'is_website_builder_enabled',
};

/*
  Upsell links always go to the first item in the product's folder, or just
  to the product itself if it's a single page.
*/
export const PREMIUM_PRODUCTS = {
  [PREMIUM_PRODUCT_KEYS.BadgeDesigner]: {
    getLabel: () => t`Badge Designer`,
    upsellNavItem: NAVIGATION_ITEMS.BADGE_DESIGNER,
  },
  [PREMIUM_PRODUCT_KEYS.LeadCapture]: {
    getLabel: () => t`Lead Capture App`,
    upsellNavItem: NAVIGATION_ITEMS.ONSITE_LEAD_CAPTURE,
  },
  [PREMIUM_PRODUCT_KEYS.OnsiteApp]: {
    getLabel: () => t`Onsite App`,
    upsellNavItem: NAVIGATION_ITEMS.ONSITE,
  },
  [PREMIUM_PRODUCT_KEYS.Registration]: {
    getLabel: () => t`Registration`,
    upsellNavItem: NAVIGATION_ITEMS.REGISTRATION_DETAILS,
  },
  [PREMIUM_PRODUCT_KEYS.EventApp]: {
    getLabel: () => t`Attendee App`,
    upsellNavItem: NAVIGATION_ITEMS.EVENT_APP_SECTIONS,
  },
  [PREMIUM_PRODUCT_KEYS.Communications]: {
    getLabel: () => t`Communications`,
    upsellNavItem: NAVIGATION_ITEMS.COMMUNICATIONS_EMAILS,
  },
  [PREMIUM_PRODUCT_KEYS.SocialAnalytics]: {
    getLabel: () => t`Social Analytics`,
    upsellNavItem: NAVIGATION_ITEMS.ANALYTICS,
  },
  [PREMIUM_PRODUCT_KEYS.Website]: {
    getLabel: () => t`Website Builder`,
    upsellNavItem: NAVIGATION_ITEMS.WEBSITE_BUILDER,
  },
};

export const COLORS = {
  primary: '#6925C8',
  secondary: '#F9F0FF',
};
