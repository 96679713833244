import apiFn from 'common/services/api-service';

export const MUTATE_ACTION = {
  CREATE: 'create',
  EDIT: 'edit',
  DUPLICATE: 'duplicate',
  DELETE: 'delete',
};

const api = eventId =>
  apiFn(`/api/uapi/events/${eventId}/website/content-pages`, {
    Accept: 'application/vnd.eventmobi+json; version=p.7',
  });

const settingsApi = eventId =>
  apiFn(`/api/uapi/events/${eventId}/website/`, {
    Accept: 'application/vnd.eventmobi+json; version=p.7',
  });

const previewLinkApi = eventId =>
  apiFn(`/api/uapi/events/${eventId}/website/preview-link`, {
    Accept: 'application/vnd.eventmobi+json; version=p.7',
  });

export function createContentPage(eventId, payload) {
  return api(eventId)
    .post('', payload)
    .then(response => response.data);
}

export function getContentPage(eventId, id) {
  return api(eventId)
    .get(id, { params: { include: 'page_design' } })
    .then(response => response.data);
}

export function updateContentPage(eventId, id, payload) {
  return api(eventId)
    .patch(id, payload)
    .then(response => response.data);
}

export function duplicateContentPage(eventId, id, payload) {
  return api(eventId)
    .post(`${id}/duplicate`, payload)
    .then(response => response.data);
}

export function deleteContentPage(eventId, id) {
  return api(eventId)
    .delete(id)
    .then(response => response.data);
}

export function listContentPages(eventId, params) {
  return api(eventId)
    .get('', { params: { ...params, include: 'page_design' } })
    .then(response => response.data);
}

export function getPreviewLink(eventId) {
  return previewLinkApi(eventId)
    .get('')
    .then(response => response.data);
}

export function getSettings(eventId) {
  return settingsApi(eventId)
    .get()
    .then(response => response.data);
}

export function updateSettings(eventId, payload) {
  return settingsApi(eventId)
    .patch('', payload)
    .then(response => response.data);
}
