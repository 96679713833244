/* global PRODUCTION, DEVELOPMENT */
/*
  The constants and logic here are derived from the Product Modularization
  Feature Guide:

  https://docs.google.com/spreadsheets/d/1_xo-6fXcFitvJ3pAz_fbyZGs5NisZVaPWCSpoyXUex0
*/
import { Space, Typography } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import { useParams } from 'react-router-dom';

import useConfig from 'common/hooks/useConfig';
import { useConfigQuery } from 'common/services/config';
import { useAssertedEventQuery } from 'common/services/events';

import { PREMIUM_PRODUCT_KEYS } from './constants';

const REFETCH_INTERVAL = 3000;

/*
  These are values configured in Hubspot so it can read the pack and tier
  from a query param.
*/
const EVENT_PACKS = {
  EventMarketing: 'event_marketing_pack',
  OnsiteApp: 'onsite_app_pack',
  AttendeeApp: 'attendee_app_pack',
  LeadCaptureApp: 'lead_capture_pack',
  SocialAnalytics: 'social_analytics_pack',
};

export const getEventPackFormUrls = isProduction => ({
  [EVENT_PACKS.EventMarketing]: isProduction
    ? 'https://go.eventmobi.com/experience-eventmarketingpack'
    : 'https://sandbox-1-eventmobi-22733385.hs-sites.com/event-marketing-mod',
  [EVENT_PACKS.OnsiteApp]: isProduction
    ? 'https://go.eventmobi.com/experience-onsiteapppack'
    : 'https://sandbox-1-eventmobi-22733385.hs-sites.com/event-marketing-mod-0',
  [EVENT_PACKS.AttendeeApp]: isProduction
    ? 'https://go.eventmobi.com/experience-attendeeapppack'
    : 'https://sandbox-1-eventmobi-22733385.hs-sites.com/event-marketing-mod-0-0',
  [EVENT_PACKS.LeadCaptureApp]: isProduction
    ? 'https://go.eventmobi.com/experience-leadcaptureapppack'
    : 'https://sandbox-1-eventmobi-22733385.hs-sites.com/event-marketing-mod-0-0-0',
  [EVENT_PACKS.SocialAnalytics]: isProduction
    ? 'https://go.eventmobi.com/experience-socialanalytics'
    : 'https://sandbox-1-eventmobi-22733385.hs-sites.com/event-marketing-mod-0-0-0-0',
});

export const productKeyToEventPack = {
  [PREMIUM_PRODUCT_KEYS.Registration]: EVENT_PACKS.EventMarketing,
  [PREMIUM_PRODUCT_KEYS.EventApp]: EVENT_PACKS.AttendeeApp,
  [PREMIUM_PRODUCT_KEYS.Communications]: EVENT_PACKS.EventMarketing,
  [PREMIUM_PRODUCT_KEYS.SocialAnalytics]: EVENT_PACKS.SocialAnalytics,
  [PREMIUM_PRODUCT_KEYS.Website]: EVENT_PACKS.EventMarketing,
  [PREMIUM_PRODUCT_KEYS.LeadCapture]: EVENT_PACKS.LeadCaptureApp,
  [PREMIUM_PRODUCT_KEYS.BadgeDesigner]: EVENT_PACKS.OnsiteApp,
  [PREMIUM_PRODUCT_KEYS.OnsiteApp]: EVENT_PACKS.OnsiteApp,
};

/*
  - Ranges are >= min, < max
  - A tier's minimum value is used as the key unless specified
  - Not all packs are tiered
*/
const eventPackTiers = {
  [EVENT_PACKS.EventMarketing]: [
    { min: 1, max: 500, key: '100-250' },
    { min: 500, max: 1000 },
    { min: 1000, max: 2500 },
    { min: 2500, max: 2501 },
  ],
  [EVENT_PACKS.OnsiteApp]: [
    { min: 1, max: 500, key: '100-250' },
    { min: 500, max: 1000 },
    { min: 1000, max: 2500 },
    { min: 2500, max: 2501 },
  ],
  [EVENT_PACKS.AttendeeApp]: [
    { min: 1, max: 250, key: '100' },
    { min: 250, max: 500 },
    { min: 500, max: 1000 },
    { min: 1000, max: 2500 },
    { min: 2500, max: 2501 },
  ],
};

const getPackTierKey = ({ eventPack, peopleLimit, hasPerPersonLicensing }) => {
  const tiers = eventPackTiers[eventPack];

  if (
    // This pack isn't tiered
    !tiers ||
    // Events without per-person licensing fall outside the tier structure
    !hasPerPersonLicensing
  ) {
    return false;
  }

  // eslint-disable-next-line no-restricted-syntax
  for (const tier of tiers) {
    if (peopleLimit >= tier.min && peopleLimit < tier.max) {
      return tier.key ?? tier.min;
    }
  }

  // None of the defined tiers cover this case
  return false;
};

const HubspotEmbed = ({ productKey }) => {
  const { eventId } = useParams();
  const config = useConfig();
  const { data } = useAssertedEventQuery(eventId);

  /*
    Since we can't communicate with the Hubspot form, we just poll for the product
    config to be updated as long as we're mounted. The PremiumRoute rendering this
    component is watching the same config value and will switch this out with
    the unlocked product page once Flux has updated..
  */
  useConfigQuery([eventId], { refetchInterval: REFETCH_INTERVAL });

  if (config.loading) {
    return null;
  }

  const hasPerPersonLicensing = config.isEnabled('per_person_licensing');
  const peopleLimit = config.get('per_person_limit');

  const eventPack = productKeyToEventPack[productKey];
  const formUrl = getEventPackFormUrls(PRODUCTION)[eventPack];
  const packTierKey = getPackTierKey({
    eventPack,
    peopleLimit,
    hasPerPersonLicensing,
  });

  // If there is no applicable tier key, we don't pass this param at all. The
  // Hubspot page will show a fallback "contact Sales" message.
  const utmTerm = packTierKey ? `${eventPack}_${packTierKey}` : '';
  const params = new URLSearchParams();

  params.set('crm_event_id', data.data.crmEventId);

  if (utmTerm) {
    params.set('utm_term', utmTerm);
  }

  const paramStr = params.toString();

  if (DEVELOPMENT) {
    return (
      <div style={{ margin: '35px 45px' }}>
        <Typography.Title level={3}>Development-mode placeholder for Hubspot</Typography.Title>
        <Typography.Text>
          You can simulate a successful purchase flow by opening <em>Event Configuration</em> in a
          separate tab and enabling the relevant product config.
        </Typography.Text>
        <Typography.Title level={4}>Values</Typography.Title>
        <Space direction="vertical">
          <Typography.Text>Product Config: {productKey}</Typography.Text>
          <Typography.Text>Event Pack: {eventPack}</Typography.Text>
          <Typography.Text>
            Per-Person Licensing: {hasPerPersonLicensing.toString()}
          </Typography.Text>
          <Typography.Text>People Limit: {peopleLimit}</Typography.Text>
          <Typography.Text>Hubspot URL params: {paramStr}</Typography.Text>
        </Space>
      </div>
    );
  }

  const src = new URL(formUrl);
  src.search = paramStr;

  return (
    // Avoid a case where two scrollbars show. Not clear why, since the iframe
    // and its container were already reporting the same height.
    <div style={{ height: '100%', width: '100%', overflow: 'hidden' }}>
      <iframe
        src={src.toString()}
        title="Product Purchase Form"
        width="100%"
        height="100%"
        style={{ border: 'none' }}
      />
    </div>
  );
};

HubspotEmbed.propTypes = {
  productKey: PropTypes.oneOf(Object.values(PREMIUM_PRODUCT_KEYS)).isRequired,
};

export default HubspotEmbed;
