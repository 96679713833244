import apiFn from 'common/services/api-service';

const api = (eventId, token) => {
  let header = { Accept: 'application/vnd.eventmobi+json; version=p.9' };
  const withCredentials = !token;

  if (token) {
    header = { ...header, Authorization: `Basic ${token}` };
  }

  return apiFn(`/api/uapi/events/${eventId}`, header, withCredentials);
};

export const getSelectedLabels = (eventId, token) =>
  api(eventId, token)
    .get('onsite-app/badges?include=design_data')
    .then(response => response.data);
