import { CrownFilled } from '@ant-design/icons';
import PropTypes from 'prop-types';
import React from 'react';

import { COLORS } from './constants';

const PremiumIcon = ({ colored } = { colored: false }) => (
  <CrownFilled style={{ color: colored ? COLORS.primary : 'inherit' }} />
);

PremiumIcon.propTypes = {
  colored: PropTypes.bool,
};

export default PremiumIcon;
