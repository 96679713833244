/* global ENV */
import { useCallback, useEffect, useReducer } from 'react';
import logger from 'use-reducer-logger';

import { transform as transformFn } from '../designer-service';
import reducer, { ACTION_POPULATE, ACTION_SET_LOADING, initialState } from '../reducer';

/**
 * A utility for loading badge design data when we're rendering instead of editing.
 * @param {object} badge You can pass design data into the hook, or use the `loadReducer`
 * function to load it.
 */
export const useDesignReducer = badge => {
  const [state, dispatch] = useReducer(ENV === 'development' ? logger(reducer) : reducer, {
    present: {
      ...initialState,
    },
  });

  const finishLoading = useCallback(() => {
    dispatch({ type: ACTION_SET_LOADING, payload: false });
  }, []);

  const loadReducer = useCallback(
    badgePayload => {
      dispatch({ type: ACTION_POPULATE, payload: transformFn(badgePayload) });
      finishLoading();
    },
    [finishLoading]
  );

  useEffect(() => {
    if (badge) {
      loadReducer(badge);
    }
  }, [badge, loadReducer]);

  return { state, loadReducer, finishLoading };
};
