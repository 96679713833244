import { Alert } from 'antd';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import styled, { createGlobalStyle } from 'styled-components';

import Loading from 'common/components/Loading';
import useSearchParams from 'common/hooks/useSearchParams';

import Builder from '../../Builder';
import { normalizeUnit } from '../../Builder/constants';
import { BadgeDesignProps } from '../../proptypes';
import { usePersonQuery } from '../../queries';
import { useSelectedLabelsQuery } from '../queries';
import { useDesignReducer } from '../useDesignReducer';

import { MOCK_PERSON_DATA_FOR_TESTING } from './constants';

const PageStyle = createGlobalStyle`
  /* Hide elements that cause blank pages to appear */
  .ReactQueryDevtools, .jodit-box {
    display: none;
  }
`;

const BadgeStyle = styled.div`
  // Set the size and orientation of the page that is rendered into the PDF (or cmd-p print dialog)
  // This relies on passing playwright the 'prefer_css_page_size' param
  // https://playwright.dev/python/docs/api/class-page#page-pdf-option-prefer-css-page-size
  @page badge_${props => props.id} {
    size: ${({ width, height }) => `${width * 4}px ${height * 4}px`};
    // All of the badges need to be rendered with a height of 57mm/2.24" (61mm/2.4" when
    // adjusted for the forced printer padding) based on how the printer is configured.
    ${({ $orientation }) => ($orientation === 'vertical' ? 'page-orientation: rotate-left;' : '')}
    page-break-after: always;
    break-after: page;
    margin: 0;
  }

  page: badge_${props => props.id};
`;

const BadgeDesign = ({ label, person, onReady, onReadyId }) => {
  const { state } = useDesignReducer(label);
  // Tell the parent component that we're ready to render
  useEffect(() => {
    if (!state.present.loading) {
      onReady(onReadyId);
    }
  }, [onReady, onReadyId, state.present.loading]);

  if (state.present.loading) {
    return null;
  }

  return (
    <BadgeStyle
      id={state.present.id}
      width={normalizeUnit(state.present.size.innerBadgeSize.width, state.present.size.unit)}
      height={normalizeUnit(state.present.size.innerBadgeSize.height, state.present.size.unit)}
      $orientation={label.orientation}
    >
      <Builder state={state} person={person} readonly showBorder={false} />
    </BadgeStyle>
  );
};

BadgeDesign.propTypes = {
  label: BadgeDesignProps.isRequired,
  person: PropTypes.shape({
    id: PropTypes.string.isRequired,
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    companyName: PropTypes.string,
    title: PropTypes.string,
    checkinCode: PropTypes.string,
    customFields: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        value: PropTypes.string,
      })
    ).isRequired,
  }).isRequired,
  onReady: PropTypes.func.isRequired,
  onReadyId: PropTypes.string.isRequired,
};

export const CaptureLabelPDF = () => {
  const query = useSearchParams();
  const token = query.get('token');
  const personId = query.get('people_id');
  const { eventId } = useParams();
  const {
    data: labelsData,
    isLoading: isLabelsLoading,
    error: labelsError,
  } = useSelectedLabelsQuery(eventId, token);
  const useMockPeopleData = query.get('use_mock_people_data')?.toLowerCase() === 'true';
  const { data: personData, error: personError } = usePersonQuery(
    eventId,
    personId,
    token,
    !useMockPeopleData
  );
  const [labelsReady, setLabelsReady] = React.useState([]);
  const onLabelReady = useCallback(id => {
    // We turn the array into a set and back into an array to ensure uniqueness. This is currently
    // overkill, but should protect against a hard to debug/reproduce issue where the PDF is
    // captured before all of the labels are ready if future changes lead to more dependencies in
    // the useEffect in BadgeDesign.
    setLabelsReady(prev => Array.from(new Set([...prev, id])));
  }, []);

  if (labelsError || personError) {
    return <Alert type="error" message={labelsError || personError} showIcon />;
  }

  const person = useMockPeopleData ? MOCK_PERSON_DATA_FOR_TESTING : personData;
  const canRenderBadges = !isLabelsLoading && !!person;
  const showLoading = !canRenderBadges || labelsReady.length < labelsData.length;

  return (
    <>
      <PageStyle />
      {showLoading && <Loading />}
      {canRenderBadges &&
        labelsData.map((label, i) => (
          <BadgeDesign
            key={label.id}
            label={label}
            person={person}
            onReady={onLabelReady}
            onReadyId={`${i}-${label.id}`}
          />
        ))}
    </>
  );
};
