import { Row } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';

import useConfig from 'common/hooks/useConfig';

import { PREMIUM_PRODUCT_KEYS } from './constants';
import PremiumIcon from './PremiumIcon';

const PremiumMenuLink = ({ product, children, ...linkProps }) => {
  const isEnabled = useConfig().isEnabled(product);

  return (
    <Link {...linkProps}>
      {isEnabled ? (
        children
      ) : (
        <Row justify="space-between" align="middle">
          {children}
          <PremiumIcon />
        </Row>
      )}
    </Link>
  );
};

PremiumMenuLink.propTypes = {
  product: PropTypes.oneOf(Object.values(PREMIUM_PRODUCT_KEYS)).isRequired,
  children: PropTypes.node.isRequired,
};

export default PremiumMenuLink;
