import { useCallback, useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { useListQuery, useMapQuery } from '../queries/permission-queries';

export const READ_EVENTS = 'read:events';
export const UPDATE_EVENTS = 'update:events';
export const CREATE_EVENTS = 'create:events';
export const DELETE_EVENTS = 'delete:events';
export const UPDATE_EVENT_ATTRIBUTES_UNRESTRICTED = 'update:events_attributes_unrestricted';

export const READ_DATA_USAGE = 'read:data_usage';

export const READ_ORGANIZERS = 'read:organizers';
export const UPDATE_ORGANIZERS = 'update:organizers';
export const CREATE_ORGANIZERS = 'create:organizers';
export const DELETE_ORGANIZERS = 'delete:organizers';

export const READ_API_KEYS = 'read:api_keys';
export const UPDATE_API_KEYS = 'update:api_keys';
export const CREATE_API_KEYS = 'create:api_keys';
export const DELETE_API_KEYS = 'delete:api_keys';

export const READ_SSO_CONNECTIONS = 'read:sso_connections';
export const UPDATE_SSO_CONNECTIONS = 'update:sso_connections';
export const CREATE_SSO_CONNECTIONS = 'create:sso_connections';
export const DELETE_SSO_CONNECTIONS = 'delete:sso_connections';

export const READ_CUSTOM_DOMAINS = 'read:custom_domains';
export const CREATE_CUSTOM_DOMAINS = 'create:custom_domains';
export const UPDATE_CUSTOM_DOMAINS = 'update:custom_domains';
export const DELETE_CUSTOM_DOMAINS = 'delete:custom_domains';

export const READ_APPS = 'read:apps';
export const UPDATE_APPS = 'update:apps';
export const CREATE_APPS = 'create:apps';
export const DELETE_APPS = 'delete:apps';

export const READ_MULTI_EVENT_APPS = 'read:multi_event_apps';
export const UPDATE_MULTI_EVENT_APPS = 'update:multi_event_apps';
export const CREATE_MULTI_EVENT_APPS = 'create:multi_event_apps';
export const DELETE_MULTI_EVENT_APPS = 'delete:multi_event_apps';
export const MODIFY_MULTI_EVENT_APP_EVENTS = 'modify:multi_event_app_events';

export const READ_CONTRACTS = 'read:contracts';
export const CREATE_CONTRACTS = 'create:contracts';
export const UPDATE_CONTRACTS = 'update:contracts';
export const DELETE_CONTRACTS = 'delete:contracts';

export const READ_ORGANIZATION = 'read:organization';
export const CREATE_ORGANIZATION = 'read:organization';
export const UPDATE_ORGANIZATION = 'update:organization';

export const READ_SENDER_EMAIL_DOMAINS = 'read:sender_email_domains';
export const CREATE_SENDER_EMAIL_DOMAINS = 'create:sender_email_domains';
export const UPDATE_SENDER_EMAIL_DOMAINS = 'update:sender_email_domains';
export const DELETE_SENDER_EMAIL_DOMAINS = 'delete:sender_email_domains';
export const VERIFY_SENDER_EMAIL_DOMAINS = 'verify:sender_email_domains';

export const CREATE_PAYMENT_GATEWAY = 'create:payment_gateway';
export const READ_PAYMENT_GATEWAY = 'read:payment_gateway';
export const UPDATE_PAYMENT_GATEWAY = 'update:payment_gateway';
export const ARCHIVE_PAYMENT_GATEWAY = 'archive:payment_gateway';
export const VERIFY_PAYMENT_GATEWAY = 'verify:payment_gateway';

const usePermission = () => {
  const { organizationId } = useParams();
  const { data: permissionSetsMap, isLoading: loadingMaps } = useMapQuery(!!organizationId);
  const { data: userPermissionSets, isLoading: loadingList } = useListQuery(
    organizationId,
    !!organizationId
  );

  const userPermissions = useMemo(() => {
    if (loadingMaps || loadingList) return {};

    return userPermissionSets.reduce((acc, permissionSet) => {
      const { permissionSetId } = permissionSet;
      const setPermissions = permissionSetsMap[permissionSetId];

      if (setPermissions) {
        setPermissions.forEach(e => {
          acc[e.permission] = true;
        });
      }

      return acc;
    }, {});
  }, [loadingMaps, loadingList, permissionSetsMap, userPermissionSets]);

  const authorized = useCallback(permission => !!userPermissions[permission], [userPermissions]);

  const authorizedAny = useCallback(
    (...permissions) => {
      return permissions.some(authorized);
    },
    [authorized]
  );

  return {
    loading: loadingMaps || loadingList,
    authorized,
    authorizedAny,
  };
};

export default usePermission;
