import { InfoCircleOutlined } from '@ant-design/icons';
import { Typography, Space, Tooltip } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import styled, { css } from 'styled-components';

import CopyButton from '@components/CopyButton';

const { Title, Text, Link: AntLink } = Typography;

const SECONDARY_COLOR = '#00000073';

const Link = styled(AntLink)`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 12px;
`;

const CopyLink = styled(CopyButton)`
  height: 12px;
  padding: 0;
  color: ${SECONDARY_COLOR};
`;

const CopyText = styled(Text)`
  color: #1890ff;
  font-size: 12px;
`;

const sharedLogoStyle = css`
  width: 58px;
  height: 58px;
  border-radius: 5px;
`;

const Icon = styled.div`
  ${sharedLogoStyle}
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #e8e8e8;
  color: #595959;
  font-size: 24px;
`;

const Image = styled.img`
  ${sharedLogoStyle}
`;

const Item = ({
  title,
  description = null,
  links,
  icon = null,
  imageUri = null,
  tooltip = null,
  canCopyLink = true,
  ...props
}) => {
  const handleClick = () => {
    if (Array.isArray(links)) {
      return;
    }

    const url = links?.url;
    if (url) {
      window.open(url, '_blank');
    }
  };

  return (
    <Space style={{ padding: '8px' }} size="middle" onClick={handleClick} {...props}>
      {imageUri && <Image src={imageUri} alt={title} />}
      {icon && !imageUri && <Icon>{icon}</Icon>}
      <Space direction="vertical" size={1}>
        <Title level={5} style={{ marginBottom: 0 }}>
          <Space>
            {title}
            {tooltip && (
              <Tooltip title={tooltip} placement="right">
                <InfoCircleOutlined
                  style={{
                    color: SECONDARY_COLOR,
                    fontSize: '12px',
                  }}
                />
              </Tooltip>
            )}
          </Space>
        </Title>
        {description && (
          <Text type="secondary" style={{ fontSize: '12px' }}>
            {description}
          </Text>
        )}
        <Space size="middle" onClick={e => e.stopPropagation()}>
          {(Array.isArray(links) ? links : [links]).map(({ url, label, icon: linkIcon }) => (
            <Space key={url}>
              {canCopyLink ? (
                <CopyLink type="text" toCopy={url} iconPosition="end">
                  <CopyText>{label}</CopyText>
                </CopyLink>
              ) : (
                <Link target="_blank" href={url}>
                  <Space>
                    {label}
                    {linkIcon}
                  </Space>
                </Link>
              )}
            </Space>
          ))}
        </Space>
      </Space>
    </Space>
  );
};

const LinkShape = PropTypes.shape({
  label: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  icon: PropTypes.node,
});

Item.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  links: PropTypes.oneOfType([LinkShape, PropTypes.arrayOf(LinkShape)]).isRequired,
  icon: PropTypes.node,
  imageUri: PropTypes.string,
  tooltip: PropTypes.string,
  canCopyLink: PropTypes.bool,
};

export default Item;
