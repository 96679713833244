function _arrayLikeToArray(arr, len) {
    if (len == null || len > arr.length) len = arr.length;
    for(var i = 0, arr2 = new Array(len); i < len; i++)arr2[i] = arr[i];
    return arr2;
}
function _arrayWithHoles(arr) {
    if (Array.isArray(arr)) return arr;
}
function _iterableToArrayLimit(arr, i) {
    var _i = arr == null ? null : typeof Symbol !== "undefined" && arr[Symbol.iterator] || arr["@@iterator"];
    if (_i == null) return;
    var _arr = [];
    var _n = true;
    var _d = false;
    var _s, _e;
    try {
        for(_i = _i.call(arr); !(_n = (_s = _i.next()).done); _n = true){
            _arr.push(_s.value);
            if (i && _arr.length === i) break;
        }
    } catch (err) {
        _d = true;
        _e = err;
    } finally{
        try {
            if (!_n && _i["return"] != null) _i["return"]();
        } finally{
            if (_d) throw _e;
        }
    }
    return _arr;
}
function _nonIterableRest() {
    throw new TypeError("Invalid attempt to destructure non-iterable instance.\\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method.");
}
function _slicedToArray(arr, i) {
    return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest();
}
function _unsupportedIterableToArray(o, minLen) {
    if (!o) return;
    if (typeof o === "string") return _arrayLikeToArray(o, minLen);
    var n = Object.prototype.toString.call(o).slice(8, -1);
    if (n === "Object" && o.constructor) n = o.constructor.name;
    if (n === "Map" || n === "Set") return Array.from(n);
    if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen);
}
import { useQuery } from "@tanstack/react-query";
import { createQueryDefinition } from "../queryBuilder";
import { get as getFn } from "./api";
export var GetEventQuery = createQueryDefinition(function(eventId) {
    return [
        eventId,
        "event"
    ];
}, function(param) {
    var _param_queryKey = _slicedToArray(param.queryKey, 1), eventId = _param_queryKey[0];
    return getFn(eventId);
});
export var useEventQuery = function(eventId) {
    return useQuery({
        queryKey: GetEventQuery.getQueryKey(eventId),
        queryFn: GetEventQuery.queryFn,
        enabled: !!eventId
    });
};
/*
  A wrapper for the event query that will error if data is not present. This
  allows us to ignore the loading state as long as the base query has been
  mounted somewhere higher and had its loading state checked there.
*/ export var useAssertedEventQuery = function(eventId) {
    var result = useEventQuery(eventId);
    if (!result.data) {
        throw new Error("Asserted event query has no data. Make sure the base event query is mounted higher in the tree and its loading state has been checked.");
    }
    return result;
};
