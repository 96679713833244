import { Alert, Button, Form, Input, message, Radio, Select as AntSelect } from 'antd';
import moment from 'moment';
import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { jt, t } from 'ttag';

import Header from 'common/components/Header';
import Label from 'common/components/Label';
import StickyFooter from 'common/components/StickyFooter';
import Url, { UrlTypes } from 'common/components/Url';
import useConfig from 'common/hooks/useConfig';
import { updateCmsEvent as updateFn } from 'common/services/events/api';
import { getEvent, setEvent as setGlobalEvent } from 'common/state/event';

import { STATUS_ACTIVE } from '@domains/Organization/contracts-service';
import { useContracts } from '@domains/Organization/DataUsage/PeopleCredit/queries';
import usePermission, { UPDATE_EVENT_ATTRIBUTES_UNRESTRICTED } from '@hooks/usePermission';

import DomainSettings from '../DomainSettings';
import EmailField from '../EmailField';
import { Field, Description } from '../StyledComponents';
import TZ from '../timezone-service';

const TIMEZONES = TZ();

const ERROR_MESSAGES_BY_CODE = () => ({
  '62601618-03db-4a2e-913b-02f978d2b3f5': t`As your event has ended, further updates to its Event Details are no longer possible.`,
  'f19564a1-376b-4a1c-b9d4-03f4e2a8c999': t`Event duration is more than max event duration.`,
});

const CONF_IS_USER_CREDIT_EVENT = 'is_user_credit_event';

// Ant Aliases
const { Option } = AntSelect;
const { useForm } = Form;

// Styled Components
const Wrap = styled.div`
  margin: 0 auto 50px;
  padding-bottom: 86px;
`;

const Select = styled(AntSelect)`
  width: 100%;
`;

const searchOptionByLabel = (inputValue, option) =>
  option.key.toLowerCase().includes(inputValue.toLowerCase());

const Settings = () => {
  const { organizationId, eventId } = useParams();
  const initial = useSelector(getEvent);
  const { authorized } = usePermission();

  const dispatch = useDispatch();

  const [form] = useForm();

  const [event, setEvent] = useState(initial);
  const [showErrorAlert, setShowErrorAlert] = useState(false);
  const [isUpdating, setUpdating] = useState(false);

  const conf = useConfig();

  const { data: contracts } = useContracts(organizationId, {
    sort: 'start_date',
  });

  const unrestrictedAccess = authorized(UPDATE_EVENT_ATTRIBUTES_UNRESTRICTED);

  const isUserCreditEvent = conf.isEnabled(CONF_IS_USER_CREDIT_EVENT);
  const hasExpired = moment().isAfter(moment(event.endDate), 'day');

  const activeContractsExist = contracts?.some(contract => contract.status === STATUS_ACTIVE);

  // only disabled when event is expired and the current user is not a staff user
  let isDisabled = hasExpired && !unrestrictedAccess;

  // Enable Event Details if it's a user credit event and there is an active contract available
  if (isUserCreditEvent && activeContractsExist) {
    isDisabled = false;
  }

  const { validateFields, setFieldValue, setFieldsValue, getFieldsValue } = form;

  const saveFn = async values => {
    try {
      const { data } = await updateFn(organizationId, eventId, {
        ...values,
      });
      setEvent(data);

      dispatch(setGlobalEvent(data));
      message.success(t`Successfully updated event.`);
    } catch (e) {
      const error = ERROR_MESSAGES_BY_CODE()[e.code] ?? t`Error updating event.`;
      message.error(error);
    } finally {
      setUpdating(false);
    }
  };

  // in case validateFields fail it shows the error alert
  const onFail = () => setShowErrorAlert(true);

  const onFinish = () => {
    setUpdating(true);
    setShowErrorAlert(false);

    const values = getFieldsValue();

    // remove `eventDates` from values as this shouldn't be sent to API
    // secondLanguageCheckbox is a view only field
    // customEmailLocal and customEmailDomain need to be combined as outboundEmailAddress
    const { customEmailLocal, customEmailDomain, ...payload } = values;

    // prepend http when the provided URL doesn't have http or https
    // its for keeping backward compatibility with previous implementation
    if (
      values.eventWebsite !== null &&
      values.eventWebsite !== '' &&
      !values.eventWebsite?.startsWith('http://') &&
      !values.eventWebsite?.startsWith('https://')
    ) {
      payload.eventWebsite = `http://${payload.eventWebsite}`;

      // Updating the form with new value for eventWebsite
      setFieldsValue({
        eventWebsite: payload.eventWebsite,
      });
    }

    // If no custom email, set it to null
    if (!payload.useCustomOutboundEmailAddress) {
      payload.outboundEmailAddress = null;
      // Updating the form with new value for outboundEmailAddress
      setFieldsValue({ outboundEmailAddress: null, customEmailLocal: '', customEmailDomain: '' });
    } else if (customEmailLocal && customEmailDomain) {
      // If the FF is enabled and there is a custom email address in two parts, merge it together
      payload.outboundEmailAddress = `${customEmailLocal}@${customEmailDomain}`;
    }

    return saveFn(payload);
  };

  // Link for support article used on Event Support Email subtext
  const emailHelpLink = (
    <a
      key="supportLink"
      href="https://help.eventmobi.com/en/knowledge/what-is-the-event-support-email-in-the-event-info-section-needed-for"
      target="_blank"
      rel="noreferrer noopener"
    >{t`Help Desk`}</a>
  );

  const [customEmailLocal = '', customEmailDomain = ''] =
    event?.outboundEmailAddress?.split('@') ?? [];

  return (
    <Wrap>
      {hasExpired && (
        <Alert
          message={t`As your event has ended, further updates to its Event Details are no longer possible.`}
          type="info"
          style={{ margin: '30px 20px' }}
          showIcon
        />
      )}

      {showErrorAlert && (
        <Alert
          message={t`Please address the errors shown below`}
          type="error"
          style={{ margin: '30px 20px' }}
          showIcon
        />
      )}
      <Form
        form={form}
        layout="vertical"
        requiredMark={false}
        onFinish={onFinish}
        onFinishFailed={onFail}
        initialValues={{
          eventWebsite: event?.eventWebsite,
          useCustomOutboundEmailAddress: event?.useCustomOutboundEmailAddress ?? false,
          customEmailDomain,
          customEmailLocal,
          supportEmailAddress: event?.supportEmailAddress,
          timezone: event?.timezone,
          timeFormat: event?.timeFormat,
          customDomainEnabled: event?.customDomainEnabled ?? false,
          customDomainStatus: event?.customDomainStatus ?? null,
        }}
      >
        <Header title={t`Event Timezone`} margin="0 0 30px" />
        <Field label={<Label>{t`Event Timezone`}</Label>} name="timezone">
          <Select
            showSearch
            placeholder={isDisabled ? t`Unavailable` : t`Please select a timezone`}
            disabled={isDisabled}
            filterOption={searchOptionByLabel}
          >
            {Object.keys(TIMEZONES).map(timezone => (
              <Select.OptGroup key={timezone}>
                {TIMEZONES[timezone].map(({ label, value }) => (
                  <Option key={label} value={value}>
                    <Label disabled={isDisabled}>{label}</Label>
                  </Option>
                ))}
              </Select.OptGroup>
            ))}
          </Select>
        </Field>
        <Field label={<Label>{t`Time Format`}</Label>} name="timeFormat">
          <Radio.Group disabled={isDisabled}>
            <Radio.Button value="12 Hour">{t`12 Hour`}</Radio.Button>
            <Radio.Button value="24 Hour">{t`24 Hour`}</Radio.Button>
          </Radio.Group>
        </Field>

        <Header title={t`Email`} />
        <EmailField event={event} isDisabled={isDisabled} isStaff={unrestrictedAccess} />

        <Field label={<Label>{t`Event Support Email`}</Label>}>
          <Field
            noStyle
            name="supportEmailAddress"
            rules={[
              {
                type: 'email',
                message: t`Invalid email address.`,
              },
            ]}
          >
            <Input disabled={isDisabled} />
          </Field>
          <Description>
            {jt`If the ${emailHelpLink} is enabled, any emails sent through it will be directed to this email.`}
          </Description>
        </Field>

        <Header title={t`Domain Settings`} />
        <DomainSettings event={event} isDisabled={isDisabled} />

        <Header title={t`Event Branding`} />
        <Field>
          <Url
            key="eventWebsite"
            label={t`Event Website`}
            name="eventWebsite"
            type={UrlTypes.Website}
            setFieldValue={setFieldValue}
            validateFields={validateFields}
            disabled={isDisabled}
          />
        </Field>

        <StickyFooter>
          <Button type="primary" htmlType="submit" loading={isUpdating} disabled={isUpdating}>
            {isUpdating ? t`Saving Event Information` : t`Save Event Information`}
          </Button>
        </StickyFooter>
      </Form>
    </Wrap>
  );
};

export default Settings;
