import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import { Route, Switch } from 'react-router-dom';

import useConfig from 'common/hooks/useConfig';
import { isEnabled as isFlagEnabled } from 'common/state/flags';

import { PREMIUM_PRODUCT_KEYS } from './constants';
import HubspotEmbed from './HubspotEmbed';

const PremiumRoute = ({ productKey, children, ...routeProps }) => {
  const isProductModularizationEnabled = useSelector(state =>
    isFlagEnabled(state, 'is_product_modularization_enabled')
  );

  const config = useConfig();
  const isProductEnabled = config.isEnabled(productKey);

  if (!isProductModularizationEnabled) {
    return (
      <Route {...routeProps}>
        <Switch>{children}</Switch>
      </Route>
    );
  }

  return (
    <Route {...routeProps}>
      {isProductEnabled && <Switch>{children}</Switch>}
      {!isProductEnabled && <HubspotEmbed productKey={productKey} />}
    </Route>
  );
};

PremiumRoute.propTypes = {
  productKey: PropTypes.oneOf(Object.values(PREMIUM_PRODUCT_KEYS)).isRequired,
  children: PropTypes.node.isRequired,
};

export default PremiumRoute;
