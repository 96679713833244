import hashFn from '@services/hash-service';
import undoable from '@state/undoable';

import { MODE_EDITOR, UNIT_DIVISION } from './Builder/constants';
import { BadgeTypes } from './constants';

const initialModal = {
  id: null,
  type: null,
  isEdit: false,
};

const initialBackground = {
  color: null,
  image: { id: null, url: null },
  size: null,
};

export const initialState = {
  id: '',
  name: '',
  size: {
    height: null,
    width: null,
    cols: null,
    rows: null,
    unit: null,
    innerBadgeSize: {
      width: null,
      height: null,
    },
  },
  badgeType: BadgeTypes.PRINT_BY_YOURSELF,
  isBleedEnabled: false,
  updatedAt: null,
  createdAt: null,

  mode: MODE_EDITOR,

  background: { ...initialBackground },
  modal: { ...initialModal },

  checksum: null,
  history: [],
  lookup: {},
  widgets: [],

  selectedWidgetId: null,

  pristine: true,
  origin: {
    widgets: [],
    lookup: {},
    background: { ...initialBackground },
  },
  loading: true,
  zoom: {
    scale: 1,
    panning: false,
  },
};

export const ACTION_OPEN_MODAL = 'OPEN_MODAL';
export const ACTION_CLOSE_MODAL = 'CLOSE_MODAL';
export const ACTION_SET_LOADING = 'SET_LOADING';
export const ACTION_EDIT_BACKGROUND = 'EDIT_BACKGROUND';

export const ACTION_POPULATE = 'POPULATE';
export const ACTION_DISCARD = 'DISCARD';

export const ACTION_NEW_WIDGET = 'NEW_WIDGET';
export const ACTION_EDIT_WIDGET = 'EDIT_WIDGET';
export const ACTION_DELETE_WIDGET = 'DELETE_WIDGET';
export const ACTION_SELECT_WIDGET = 'SELECT_WIDGET';
export const ACTION_DESELECT_WIDGET = 'DESELECT_WIDGET';
export const ACTION_MOVE_WIDGET_FRONT = 'MOVE_WIDGET_FRONT';
export const ACTION_MOVE_WIDGET_FORWARD = 'MOVE_WIDGET_FORWARD';
export const ACTION_MOVE_WIDGET_BACKWARD = 'MOVE_WIDGET_BACKWARD';
export const ACTION_MOVE_WIDGET_BACK = 'MOVE_WIDGET_BACK';

export const ACTION_UPDATE_WIDGETS = 'UPDATE_WIDGETS';

export const ACTION_SET_MODE = 'SET_MODE';

export const ACTION_SET_ZOOM_SCALE = 'SET_ZOOM_SCALE';
export const ACTION_SET_ZOOM_PANNING = 'SET_ZOOM_PANNING';

const SKIP_ACTIONS_HISTORY = [
  ACTION_SET_LOADING,
  ACTION_OPEN_MODAL,
  ACTION_CLOSE_MODAL,
  ACTION_POPULATE,
  ACTION_SELECT_WIDGET,
  ACTION_DESELECT_WIDGET,
  ACTION_SET_MODE,
  ACTION_SET_ZOOM_SCALE,
  ACTION_SET_ZOOM_PANNING,
];

function skipHistory({ type }) {
  if (SKIP_ACTIONS_HISTORY.includes(type)) {
    return true;
  }

  return false;
}

const reducer = (state, action) => {
  switch (action.type) {
    case ACTION_POPULATE: {
      const {
        background,
        widgets,
        id,
        name,
        updatedAt,
        badgeType,
        isBleedEnabled,
        size: { width, height, unit, innerBadgeSize },
      } = action.payload;

      const size = {
        width,
        height,
        unit,
        cols: width * UNIT_DIVISION[unit],
        rows: height * UNIT_DIVISION[unit],
        innerBadgeSize,
      };

      // use white color as fallback background
      if (!background.color && !background.url) {
        background.color = '#FFFFFF';
      }

      const lookup = widgets.reduce(
        (prev, next, i) => ({ ...prev, [next.id]: { ...next, i } }),
        {}
      );
      const checksum = hashFn({ lookup, background });

      return {
        ...state,
        loading: false,
        pristine: true,
        history: [checksum],
        origin: JSON.parse(JSON.stringify({ background, widgets, lookup })),
        id,
        checksum,
        name,
        size,
        updatedAt,
        badgeType,
        isBleedEnabled,
        background,
        widgets,
        lookup,
        selectedWidgetId: null,
      };
    }
    case ACTION_DISCARD: {
      const { background, lookup, widgets } = state.origin;
      const checksum = hashFn({ lookup, background });

      return {
        ...state,
        pristine: true,
        history: [checksum],
        background: { ...background },
        lookup: { ...lookup },
        widgets: [...widgets],
        checksum,
        selectedWidgetId: null,
      };
    }
    case ACTION_SET_LOADING: {
      return { ...state, loading: action.payload };
    }
    case ACTION_OPEN_MODAL: {
      return {
        ...state,
        modal: { ...state.modal, ...action.payload },
      };
    }
    case ACTION_CLOSE_MODAL: {
      return {
        ...state,
        modal: {
          ...initialModal,
        },
      };
    }
    case ACTION_NEW_WIDGET: {
      const widgets = [...state.widgets, { ...action.payload, new: true }];
      const lookup = widgets.reduce(
        (prev, next, i) => ({ ...prev, [next.id]: { ...next, i } }),
        {}
      );
      const checksum = hashFn({ lookup, background: state.background });

      return {
        ...state,
        selectedWidgetId: action.payload.id,
        pristine: false,
        history: Array.from(new Set([...state.history, checksum])),
        widgets,
        lookup,
        checksum,
      };
    }
    case ACTION_EDIT_WIDGET: {
      const { id, params, layout } = action.payload;

      const src = state.lookup[id];
      const updated = {
        ...src,
        params: { ...src.params, ...params },
        layout: { ...src.layout, ...layout },
      };

      const index = state.widgets.findIndex(w => w.id === id);

      state.widgets.splice(index, 1, { ...updated });

      const widgets = [...state.widgets];
      const lookup = { ...state.lookup, [id]: { ...updated } };
      const checksum = hashFn({ lookup, background: state.background });

      return {
        ...state,
        pristine: state.checksum === null || state.history?.[0] === checksum,
        history: Array.from(new Set([...state.history, checksum])),
        checksum,
        widgets,
        lookup,
      };
    }
    case ACTION_DELETE_WIDGET: {
      const { id } = action.payload;

      const index = state.widgets.findIndex(w => w.id === id);

      state.widgets.splice(index, 1);

      const { [id]: _, ...lookup } = state.lookup;

      const widgets = [...state.widgets];
      const checksum = hashFn({ lookup, background: state.background });

      return {
        ...state,
        widgets,
        lookup,
        selectedWidgetId: null,
        pristine: state.checksum === null || state.history?.[0] === checksum,
        history: Array.from(new Set([...state.history, checksum])),
      };
    }
    case ACTION_EDIT_BACKGROUND: {
      const background = { ...action.payload };
      const checksum = hashFn({ lookup: state.lookup, background });

      return {
        ...state,
        pristine: false,
        history: Array.from(new Set([...state.history, checksum])),
        background,
        checksum,
      };
    }
    case ACTION_UPDATE_WIDGETS: {
      const { widgets } = action.payload;
      const lookup = widgets.reduce(
        (prev, next, i) => ({ ...prev, [next.id]: { ...next, i } }),
        {}
      );
      const checksum = hashFn({ lookup, background: state.background });

      return {
        ...state,
        lookup,
        widgets,
        checksum,
        pristine: state.checksum === null || state.history?.[0] === checksum,
        history: Array.from(new Set([...state.history, checksum])),
      };
    }
    case ACTION_SELECT_WIDGET: {
      const widgetId = action.payload;
      return {
        ...state,
        selectedWidgetId: widgetId,
      };
    }
    case ACTION_DESELECT_WIDGET: {
      return {
        ...state,
        selectedWidgetId: null,
      };
    }
    case ACTION_MOVE_WIDGET_FRONT:
    case ACTION_MOVE_WIDGET_FORWARD:
    case ACTION_MOVE_WIDGET_BACKWARD:
    case ACTION_MOVE_WIDGET_BACK: {
      const { id } = action.payload;
      const targetWidgetIndex = state.widgets.findIndex(widget => widget.id === id);
      const targetWidget = state.widgets[targetWidgetIndex];

      let newWidgetIndex = targetWidgetIndex;
      if (action.type === ACTION_MOVE_WIDGET_FRONT) {
        newWidgetIndex = state.widgets.length;
      }
      if (action.type === ACTION_MOVE_WIDGET_FORWARD && targetWidgetIndex < state.widgets.length) {
        newWidgetIndex = targetWidgetIndex + 1;
      }
      if (action.type === ACTION_MOVE_WIDGET_BACKWARD && targetWidgetIndex > 0) {
        newWidgetIndex = targetWidgetIndex - 1;
      }
      if (action.type === ACTION_MOVE_WIDGET_BACK) {
        newWidgetIndex = 0;
      }

      const widgets = [...state.widgets].filter(widget => widget.id !== id);
      widgets.splice(newWidgetIndex, 0, targetWidget);

      const lookup = widgets.reduce(
        (prev, next, i) => ({ ...prev, [next.id]: { ...next, i } }),
        {}
      );
      const checksum = hashFn({ lookup, background: state.background });

      return {
        ...state,
        lookup,
        widgets,
        checksum,
        pristine: state.checksum === null || state.history?.[0] === checksum,
        history: Array.from(new Set([...state.history, checksum])),
      };
    }
    case ACTION_SET_MODE: {
      const mode = action.payload;
      return { ...state, mode };
    }
    case ACTION_SET_ZOOM_SCALE: {
      const scale = action.payload;
      return { ...state, zoom: { ...state.zoom, scale } };
    }
    case ACTION_SET_ZOOM_PANNING: {
      const panning = action.payload === undefined ? !state.zoom.panning : action.payload;

      return {
        ...state,
        selectedWidgetId: panning ? null : state.selectedWidgetId,
        zoom: { ...state.zoom, panning },
      };
    }
    default:
      return { ...initialState };
  }
};

export default undoable(reducer, skipHistory, ['modal', 'selectedWidgetId']);
