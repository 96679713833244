import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';

import useConfig from 'common/hooks/useConfig';
import useSearchParams from 'common/hooks/useSearchParams';

import {
  createContentPage,
  duplicateContentPage,
  getContentPage,
  getPreviewLink,
  deleteContentPage,
  getSettings,
  listContentPages,
  MUTATE_ACTION,
  updateContentPage,
  updateSettings,
} from './service';

const { CREATE, EDIT, DUPLICATE, DELETE } = MUTATE_ACTION;

const getContentPagesBaseQueryKey = eventId => [
  'events',
  String(eventId),
  'website-builder',
  'content-pages',
];

export const contentPageOptions = (eventId, pageId) => ({
  queryKey: [...getContentPagesBaseQueryKey(eventId), String(pageId)],
  queryFn: () => getContentPage(eventId, pageId),
  select: ({ data }) => data,
});

export const useContentPage = pageId => {
  const { eventId } = useParams();
  return useQuery(contentPageOptions(eventId, pageId));
};

export const contentPagesOptions = (eventId, search) => ({
  queryKey: [...getContentPagesBaseQueryKey(eventId), { search: search || '' }],
  queryFn: () => listContentPages(eventId, { search }),
  select: ({ data }) => data,
  keepPreviousData: true,
});

export const useContentPagesQuery = () => {
  const { eventId } = useParams();
  const query = useSearchParams();

  const search = query.get('search');

  return useQuery(contentPagesOptions(eventId, search));
};

const createMutationOptions = ({ eventId }) => ({
  mutationKey: ['website-builder', 'content-pages', 'create'],
  mutationFn: ({ payload }) => createContentPage(eventId, payload),
});

const editMutationOptions = ({ eventId }) => ({
  mutationKey: ['website-builder', 'content-pages', 'edit'],
  mutationFn: ({ payload, pageId }) => updateContentPage(eventId, pageId, payload),
});

const duplicateMutationOptions = ({ eventId }) => ({
  mutationKey: ['website-builder', 'content-pages', 'duplicate'],
  mutationFn: ({ payload, pageId }) => duplicateContentPage(eventId, pageId, payload),
});

const deleteMutationOptions = ({ eventId }) => ({
  mutationKey: ['website-builder', 'content-pages', 'delete'],
  mutationFn: ({ pageId }) => deleteContentPage(eventId, pageId),
});

// Preview link expires after 1 hour, but we refetch it a little
// more often than that to account for any potential latency.
const PREVIEW_LINK_REFETCH_INTERVAL = 57 * 60 * 1000;

export const previewLinkQueryOptions = eventId => ({
  queryKey: ['events', String(eventId), 'website-builder', 'preview-link'],
  queryFn: () => getPreviewLink(eventId),
  select: ({ data }) => data,
  refetchInterval: PREVIEW_LINK_REFETCH_INTERVAL,
});

export const usePreviewLinkQuery = () => {
  const { eventId } = useParams();
  return useQuery(previewLinkQueryOptions(eventId));
};

export const settingsQueryOptions = (eventId, enabled = true) => ({
  queryKey: ['events', String(eventId), 'website-builder', 'settings'],
  queryFn: () => getSettings(eventId),
  select: ({ data }) => data,
  enabled,
});

export const useSettingsQuery = () => {
  const { eventId } = useParams();
  const config = useConfig();
  const isWebsiteBuilderEnabled = config.isEnabled('is_website_builder_enabled');

  const { isLoading, ...rest } = useQuery(settingsQueryOptions(eventId, isWebsiteBuilderEnabled));

  return {
    isLoading: isLoading && isWebsiteBuilderEnabled,
    ...rest,
  };
};

export const useContentPageMutations = action => {
  const { eventId } = useParams();
  const queryClient = useQueryClient();
  const query = useSearchParams();
  const search = query.get('search');

  const mutationOptions = {
    [CREATE]: createMutationOptions,
    [EDIT]: editMutationOptions,
    [DUPLICATE]: duplicateMutationOptions,
    [DELETE]: deleteMutationOptions,
  }[action]({ eventId });

  return useMutation({
    ...mutationOptions,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: contentPagesOptions(eventId, search).queryKey });
      queryClient.removeQueries({ queryKey: settingsQueryOptions(eventId).queryKey });
    },
  });
};

export const useSettingsMutation = () => {
  const queryClient = useQueryClient();
  const { eventId } = useParams();

  return useMutation({
    mutationKey: ['website-builder', 'settings', 'update'],
    mutationFn: payload => updateSettings(eventId, payload),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: settingsQueryOptions(eventId).queryKey });
    },
  });
};
