import PropTypes from 'prop-types';

import { UNIT_INCH, UNIT_MM } from './constants';

// This is the shape after running `prepareDataForBleed` and `transform` on the API response
export const BadgeDesignProps = PropTypes.shape({
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  size: PropTypes.shape({
    width: PropTypes.string.isRequired,
    height: PropTypes.string.isRequired,
    unitType: PropTypes.oneOf([UNIT_MM, UNIT_INCH]).isRequired,
    innerBadgeSize: PropTypes.shape({
      width: PropTypes.number.isRequired,
      height: PropTypes.number.isRequired,
    }).isRequired,
  }).isRequired,
  designData: PropTypes.shape({
    backgroundColor: PropTypes.string,
    backgroundImage: PropTypes.string,
    backgroundSize: PropTypes.string,
    widgets: PropTypes.arrayOf(
      PropTypes.shape({
        type: PropTypes.string.isRequired,
        x: PropTypes.number.isRequired,
        y: PropTypes.number.isRequired,
        z: PropTypes.number.isRequired,
        width: PropTypes.string.isRequired,
        height: PropTypes.string.isRequired,
      })
    ),
  }),
});
