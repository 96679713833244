import { Button, Form, message, Modal, Typography, Select, Input } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import { t } from 'ttag';

import Label from 'common/components/Label';

import { useLinkEventsMutation } from './queries';

const { Item, useForm } = Form;
const { Text } = Typography;

const CUSTOM_DOMAIN_NOT_FOUND = 'custom_domain_not_found';
const CUSTOM_DOMAIN_UNVERIFIED = 'custom_domain_unverified';
const CUSTOM_DOMAIN_EVENT_ALREADY_LINKED = 'custom_domain_event_already_linked';

const ERROR_MESSAGES_BY_CODE = () => ({
  [CUSTOM_DOMAIN_NOT_FOUND]: t`Custom domain does not exist`,
  [CUSTOM_DOMAIN_UNVERIFIED]: t`The custom domain is not verified`,
  [CUSTOM_DOMAIN_EVENT_ALREADY_LINKED]: t`The event is already linked to another custom domain`,
});

const LinkEvents = ({ organizationId, events, domain: { id, ...rest }, onClose }) => {
  const { mutate: linkEventsFn, isLoading } = useLinkEventsMutation();

  const [form] = useForm();

  const handleSave = async () => {
    try {
      const { linkedEvents, rootDomainRedirectUrl } = await form.validateFields();

      linkEventsFn(
        { organizationId, id, linkedEvents, rootDomainRedirectUrl },
        {
          onSuccess: onClose,
          onError: err => {
            const errorMessage =
              ERROR_MESSAGES_BY_CODE()[err?.errors?.[0]?.code] ?? t`An unknown error occurred`;
            message.error(errorMessage);
          },
        }
      );
    } catch (err) {
      if (err?.errorFields) {
        return;
      }
      message.error(t`An unknown error occurred`);
    }
  };

  return (
    <Modal
      title={t`Linked Events`}
      open
      maskClosable={false}
      destroyOnClose
      onCancel={onClose}
      footer={
        <>
          <Button onClick={onClose}>{t`Cancel`}</Button>
          <Button loading={isLoading} onClick={handleSave} type="primary">
            {t`Save`}
          </Button>
        </>
      }
      width={700}
    >
      <Form
        form={form}
        layout="vertical"
        initialValues={{
          ...rest,
          linkedEvents: rest?.linkedEvents?.map(event => event.id) ?? [],
        }}
      >
        <Item
          label={
            <div>
              <Label>{t`Events`}</Label>
              <Text type="secondary">
                {t`Please add the events to use with this custom domain.`}
              </Text>
            </div>
          }
          name="linkedEvents"
        >
          <Select
            testId="linkedEvents"
            placeholder={t`Event Code`}
            mode="multiple"
            allowClear
            optionFilterProp="label"
            options={events?.map(({ id: value, shortcode: label }) => ({ value, label }))}
          />
        </Item>
        <Item
          name="rootDomainRedirectUrl"
          label={
            <div>
              <Label>{t`Redirect URL`}</Label>
              <Text type="secondary">
                {t`Enter the URL where users will be redirected (e.g., your marketing website or landing page)`}
              </Text>
            </div>
          }
          rules={[{ type: 'url', message: 'Enter valid url' }]}
        >
          <Input placeholder="Enter Redirect URL" />
        </Item>
      </Form>
    </Modal>
  );
};

LinkEvents.propTypes = {
  organizationId: PropTypes.string.isRequired,
  events: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      shortcode: PropTypes.string.isRequired,
    })
  ).isRequired,
  domain: PropTypes.shape({
    id: PropTypes.string.isRequired,
    linkedEvents: PropTypes.arrayOf(PropTypes.string),
    rootDomainRedirectUrl: PropTypes.string,
  }).isRequired,
  onClose: PropTypes.func.isRequired,
};

export default LinkEvents;
