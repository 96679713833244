import {
  ApiOutlined,
  DashboardOutlined,
  DatabaseOutlined,
  DesktopOutlined,
  GlobalOutlined,
  LayoutOutlined,
  MailOutlined,
  MobileOutlined,
  PieChartOutlined,
  TagOutlined,
  ToolOutlined,
} from '@ant-design/icons';
import { Menu as AntMenu } from 'antd';
import React from 'react';
import { useSelector } from 'react-redux';
import { matchPath, Link, useLocation } from 'react-router-dom';
import styled, { createGlobalStyle } from 'styled-components';
import { t } from 'ttag';

import useConfig from 'common/hooks/useConfig';
import { SIDE_NAV_WIDTH, useSideNavExpanded } from 'common/hooks/useSideNavExpanded';
import { isEnabled as isFlagEnabled } from 'common/state/flags';
import { getIsStaff } from 'common/state/user';

import { PREMIUM_PRODUCT_KEYS } from '@domains/Premium/constants';
import PremiumMenuLink from '@domains/Premium/PremiumMenuLink';

import { NAVIGATION_ITEMS, NAVIGATION_ROUTES, useGetNavigationPath } from './navigation-items';

const StyleOverrides = createGlobalStyle`
  .ant-menu-item-group-title {
    text-transform: uppercase;
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 1px;
  }

  .ant-menu-item-divider {
    && {
      border-color: rgba(255, 255, 255, 0.25);
    }
  }
  `;

const Menu = styled(AntMenu)`
  height: 100%;
  overflow: auto;
  width: ${SIDE_NAV_WIDTH.Expanded};
  flex-shrink: 0;
  z-index: 999;

  & .ant-menu-inline.ant-menu-sub {
    background: #192738;
  }

  &.ant-menu-inline-collapsed {
    width: ${SIDE_NAV_WIDTH.Collapsed};
  }
`;

// List of paths where we don't want to show the navigation sidebar.
const noSideNavPaths = [
  `${
    NAVIGATION_ROUTES[NAVIGATION_ITEMS.COMMUNICATIONS_EMAILS].path
  }/templates/:emailDesignId/editor`,
  `${NAVIGATION_ROUTES[NAVIGATION_ITEMS.WEBSITE_BUILDER].path}/templates/:pageDesignId/editor`,
];

export const useIsNoSideNavPath = () => {
  const { pathname } = useLocation();
  return noSideNavPaths.some(noNavPath => matchPath(pathname, { path: noNavPath, exact: true }));
};

const getSelectedMenuKeys = url => {
  // eslint-disable-next-line no-restricted-syntax
  for (const [key, route] of Object.entries(NAVIGATION_ROUTES)) {
    if (matchPath(url, route)) {
      return [key];
    }
  }

  return [NAVIGATION_ITEMS.EVENT_DETAILS];
};

const Navigation = () => {
  const isSideNavExpanded = useSideNavExpanded();
  const { pathname } = useLocation();
  const config = useConfig();

  const isRegistrationEnabled = config.isEnabled('is_registration_v2_enabled');
  const isSessionsEnabled = config.isEnabled('is_sessions_enabled');
  const isGamificationEnabled = config.isEnabled('is_gamify_app_enabled');
  const isCompaniesEnabled = config.isEnabled('is_companies_enabled');
  const isDocumentUploadEnabled = config.isEnabled('is_document_upload_enabled');
  const isSurveyEnabled = config.isEnabled('is_survey_enabled');
  const isGroupDiscussionsEnabled = config.isEnabled('is_group_discussions_enabled');
  const isActivityFeedEnabled = config.isEnabled('is_activity_feed_enabled');
  const isSponsorshipEnabled = config.isEnabled('is_sponsorship_enabled');
  const isAnnouncementsEnabled = config.isEnabled('is_announcements_enabled');
  const isInteractiveMapsEnabled = config.isEnabled('is_interactive_maps_enabled');

  const isIntegrationDashboardEnabled = useSelector(state =>
    isFlagEnabled(state, 'integrations_dashboard')
  );
  const isRegFieldsEnabled = useSelector(state => isFlagEnabled(state, 'reg_field_updates'));
  const isVideosEnabled = config.isEnabled('is_videos_enabled');
  const isWebsiteBuilderEnabled = config.isEnabled('is_website_builder_enabled');
  const isEventAppEnabled = config.isEnabled('is_eventspace_enabled');
  const isAnalyticsEnabled = config.isEnabled('is_analytics_enabled');
  const isDashboardEnabled = useSelector(state => isFlagEnabled(state, 'is_dashboard_enabled'));

  const isStaff = useSelector(getIsStaff);

  const getNavPath = useGetNavigationPath();

  /** @type {Array<import('antd/lib/menu/hooks/useItems').MenuItemType>} */
  const items = [
    isDashboardEnabled && {
      key: NAVIGATION_ITEMS.DASHBOARD,
      icon: <DashboardOutlined />,
      label: <Link to={getNavPath(NAVIGATION_ITEMS.DASHBOARD)}>{t`Overview`}</Link>,
    },
    {
      label: t`General`,
      icon: <GlobalOutlined />,
      children: [
        {
          key: NAVIGATION_ITEMS.EVENT_DETAILS,
          label: <Link to={getNavPath(NAVIGATION_ITEMS.EVENT_DETAILS)}>{t`Event Details`}</Link>,
        },
        {
          key: NAVIGATION_ITEMS.PRIVACY_AND_TERMS,
          label: (
            <Link to={getNavPath(NAVIGATION_ITEMS.PRIVACY_AND_TERMS)}>{t`Privacy & Terms`}</Link>
          ),
        },
      ],
    },
    {
      label: t`Libraries`,
      icon: <DatabaseOutlined />,
      children: [
        {
          key: NAVIGATION_ITEMS.PEOPLE,
          label: <Link to={getNavPath(NAVIGATION_ITEMS.PEOPLE)}>{t`People`}</Link>,
        },
        isSessionsEnabled && {
          key: NAVIGATION_ITEMS.SESSIONS,
          label: <Link to={getNavPath(NAVIGATION_ITEMS.SESSIONS)}>{t`Sessions`}</Link>,
        },
        isCompaniesEnabled && {
          key: NAVIGATION_ITEMS.COMPANIES,
          label: <Link to={getNavPath(NAVIGATION_ITEMS.COMPANIES)}>{t`Companies`}</Link>,
        },
        isDocumentUploadEnabled && {
          key: NAVIGATION_ITEMS.DOCUMENTS,
          label: <Link to={getNavPath(NAVIGATION_ITEMS.DOCUMENTS)}>{t`Documents`}</Link>,
        },
        isVideosEnabled && {
          key: NAVIGATION_ITEMS.VIDEOS,
          label: <Link to={getNavPath(NAVIGATION_ITEMS.VIDEOS)}>{t`Videos`}</Link>,
        },
        isInteractiveMapsEnabled && {
          key: NAVIGATION_ITEMS.MAPS,
          label: <Link to={getNavPath(NAVIGATION_ITEMS.MAPS)}>{t`Maps`}</Link>,
        },
        isSponsorshipEnabled && {
          key: NAVIGATION_ITEMS.EVENT_APP_BANNERS,
          label: <Link to={getNavPath(NAVIGATION_ITEMS.EVENT_APP_BANNERS)}>{t`Banners`}</Link>,
        },
      ],
    },
    isWebsiteBuilderEnabled && {
      key: NAVIGATION_ITEMS.WEBSITE_BUILDER,
      icon: <DesktopOutlined />,
      label: <Link to={getNavPath(NAVIGATION_ITEMS.WEBSITE_BUILDER)}>{t`Website`}</Link>,
    },
    {
      key: NAVIGATION_ITEMS.COMMUNICATIONS,
      icon: <MailOutlined />,
      label: t`Communications`,
      children: [
        {
          key: NAVIGATION_ITEMS.COMMUNICATIONS_EMAILS,
          label: (
            <PremiumMenuLink
              product={PREMIUM_PRODUCT_KEYS.Communications}
              to={getNavPath(NAVIGATION_ITEMS.COMMUNICATIONS_EMAILS)}
            >{t`Email`}</PremiumMenuLink>
          ),
        },
        isAnnouncementsEnabled && {
          key: NAVIGATION_ITEMS.ANNOUNCEMENTS,
          label: <Link to={getNavPath(NAVIGATION_ITEMS.ANNOUNCEMENTS)}>{t`Announcements`}</Link>,
        },
      ],
    },
    isRegistrationEnabled && {
      key: NAVIGATION_ITEMS.REGISTRATION,
      icon: <TagOutlined />,
      label: t`Registration`,
      children: [
        {
          key: NAVIGATION_ITEMS.REGISTRATION_DETAILS,
          label: (
            <Link
              to={getNavPath(NAVIGATION_ITEMS.REGISTRATION_DETAILS)}
            >{t`Registration Details`}</Link>
          ),
        },
        {
          key: NAVIGATION_ITEMS.REGISTRATION_TICKETS,
          label: <Link to={getNavPath(NAVIGATION_ITEMS.REGISTRATION_TICKETS)}>{t`Tickets`}</Link>,
        },
        isRegFieldsEnabled && {
          key: NAVIGATION_ITEMS.REGISTRATION_REG_FORM,
          label: (
            <Link to={getNavPath(NAVIGATION_ITEMS.REGISTRATION_REG_FORM)}>
              {t`Registration Form`}
            </Link>
          ),
        },
        {
          key: NAVIGATION_ITEMS.REGISTRATION_PROMO_CODES,
          label: (
            <Link to={getNavPath(NAVIGATION_ITEMS.REGISTRATION_PROMO_CODES)}>{t`Promo Codes`}</Link>
          ),
        },
        {
          key: NAVIGATION_ITEMS.REGISTRATION_EMAILS,
          label: <Link to={getNavPath(NAVIGATION_ITEMS.REGISTRATION_EMAILS)}>{t`Emails`}</Link>,
        },
        {
          key: NAVIGATION_ITEMS.REGISTRATION_ORDERS,
          label: <Link to={getNavPath(NAVIGATION_ITEMS.REGISTRATION_ORDERS)}>{t`Orders`}</Link>,
        },
      ],
    },
    isEventAppEnabled && {
      key: NAVIGATION_ITEMS.EVENT_APP,
      icon: <LayoutOutlined />,
      label: t`Event App`,
      children: [
        {
          key: NAVIGATION_ITEMS.EVENT_APP_SECTIONS,
          label: (
            <Link to={getNavPath(NAVIGATION_ITEMS.EVENT_APP_SECTIONS)}>{t`Menu Sections`}</Link>
          ),
        },
        {
          key: NAVIGATION_ITEMS.EVENT_APP_HOME_PAGE,
          label: <Link to={getNavPath(NAVIGATION_ITEMS.EVENT_APP_HOME_PAGE)}>{t`Home Page`}</Link>,
        },
        isActivityFeedEnabled && {
          key: NAVIGATION_ITEMS.EVENT_APP_ACTIVITY_FEED,
          label: (
            <Link
              to={getNavPath(NAVIGATION_ITEMS.EVENT_APP_ACTIVITY_FEED)}
            >{t`Activity Feed`}</Link>
          ),
        },
        isGroupDiscussionsEnabled && {
          key: NAVIGATION_ITEMS.EVENT_APP_GROUP_DISCUSSIONS,
          label: (
            <Link
              to={getNavPath(NAVIGATION_ITEMS.EVENT_APP_GROUP_DISCUSSIONS)}
            >{t`Group Discussions`}</Link>
          ),
        },
        isGamificationEnabled && {
          key: NAVIGATION_ITEMS.GAMIFICATION,
          label: <Link to={getNavPath(NAVIGATION_ITEMS.GAMIFICATION)}>{t`Gamification`}</Link>,
        },
        isSurveyEnabled && {
          key: NAVIGATION_ITEMS.SURVEYS,
          label: <Link to={getNavPath(NAVIGATION_ITEMS.SURVEYS)}>{t`Surveys`}</Link>,
        },
        {
          key: NAVIGATION_ITEMS.AUDIENCE_RESPONSE,
          label: (
            <Link to={getNavPath(NAVIGATION_ITEMS.AUDIENCE_RESPONSE)}>{t`Audience Response`}</Link>
          ),
        },
        {
          key: NAVIGATION_ITEMS.EVENT_APP_DESIGN_STUDIO,
          label: (
            <Link to={getNavPath(NAVIGATION_ITEMS.EVENT_APP_DESIGN_STUDIO)}>
              {t`Event App Design`}
            </Link>
          ),
        },
        {
          key: NAVIGATION_ITEMS.EVENT_APP_SETTINGS,
          label: (
            <Link
              to={getNavPath(NAVIGATION_ITEMS.EVENT_APP_SETTINGS)}
            >{t`Event App Settings`}</Link>
          ),
        },
        {
          key: NAVIGATION_ITEMS.WIDGET_PAGES,
          label: <Link to={getNavPath(NAVIGATION_ITEMS.WIDGET_PAGES)}>{t`Page Designer`}</Link>,
        },
      ],
    },
    {
      label: t`Onsite Tools`,
      icon: <MobileOutlined />,
      children: [
        {
          key: NAVIGATION_ITEMS.BADGE_DESIGNER,
          label: (
            <PremiumMenuLink
              product={PREMIUM_PRODUCT_KEYS.BadgeDesigner}
              to={getNavPath(NAVIGATION_ITEMS.BADGE_DESIGNER)}
            >{t`Badge Designer`}</PremiumMenuLink>
          ),
        },
        {
          key: NAVIGATION_ITEMS.ONSITE,
          label: (
            <PremiumMenuLink
              product={PREMIUM_PRODUCT_KEYS.OnsiteApp}
              to={getNavPath(NAVIGATION_ITEMS.ONSITE)}
            >{t`Onsite App`}</PremiumMenuLink>
          ),
        },
        {
          key: NAVIGATION_ITEMS.ONSITE_LEAD_CAPTURE,
          label: (
            <PremiumMenuLink
              product={PREMIUM_PRODUCT_KEYS.LeadCapture}
              to={getNavPath(NAVIGATION_ITEMS.ONSITE_LEAD_CAPTURE)}
            >{t`Lead Capture App`}</PremiumMenuLink>
          ),
        },
        {
          key: NAVIGATION_ITEMS.LIVE_DISPLAY,
          label: <Link to={getNavPath(NAVIGATION_ITEMS.LIVE_DISPLAY)}>{t`Live Display`}</Link>,
        },
      ],
    },
    isIntegrationDashboardEnabled && {
      key: NAVIGATION_ITEMS.INTEGRATIONS,
      icon: <ApiOutlined />,
      label: <Link to={getNavPath(NAVIGATION_ITEMS.INTEGRATIONS)}>{t`Integrations`}</Link>,
    },
    isAnalyticsEnabled && {
      key: NAVIGATION_ITEMS.ANALYTICS,
      icon: <PieChartOutlined />,
      label: <Link to={getNavPath(NAVIGATION_ITEMS.ANALYTICS)}>{t`Analytics`}</Link>,
    },
    {
      label: t`Admin`,
      icon: <ToolOutlined />,
      children: [
        {
          key: NAVIGATION_ITEMS.AUDIT_LOG,
          label: <Link to={getNavPath(NAVIGATION_ITEMS.AUDIT_LOG)}>{t`Log Inspector`}</Link>,
        },
        isStaff && {
          key: NAVIGATION_ITEMS.EVENT_CONFIGURATION,
          label: (
            <Link
              to={getNavPath(NAVIGATION_ITEMS.EVENT_CONFIGURATION)}
            >{t`Event Configuration`}</Link>
          ),
        },
      ],
    },
  ];

  // These items should alaways link to the path of the first item in their folder
  const premiumGroupItems = [
    !isWebsiteBuilderEnabled && {
      key: NAVIGATION_ITEMS.WEBSITE_BUILDER,
      icon: <DesktopOutlined />,
      label: (
        <PremiumMenuLink
          product={PREMIUM_PRODUCT_KEYS.Website}
          to={getNavPath(NAVIGATION_ITEMS.WEBSITE_BUILDER)}
        >{t`Website`}</PremiumMenuLink>
      ),
    },
    !isRegistrationEnabled && {
      key: NAVIGATION_ITEMS.REGISTRATION,
      icon: <TagOutlined />,
      label: (
        <PremiumMenuLink
          product={PREMIUM_PRODUCT_KEYS.Registration}
          to={getNavPath(NAVIGATION_ITEMS.REGISTRATION_DETAILS)}
        >{t`Registration`}</PremiumMenuLink>
      ),
    },
    !isEventAppEnabled && {
      key: NAVIGATION_ITEMS.EVENT_APP,
      icon: <LayoutOutlined />,
      label: (
        <PremiumMenuLink
          product={PREMIUM_PRODUCT_KEYS.EventApp}
          to={getNavPath(NAVIGATION_ITEMS.EVENT_APP_SECTIONS)}
        >{t`Event App`}</PremiumMenuLink>
      ),
    },
  ].filter(Boolean);

  if (isSideNavExpanded && premiumGroupItems.length > 0) {
    items.push(
      {
        type: 'divider',
      },
      {
        type: 'group',
        label: t`Power Up Your Event`,
        children: premiumGroupItems,
      }
    );
  }

  return [
    <StyleOverrides key="overrides" />,
    <Menu
      key="left-nav"
      selectedKeys={getSelectedMenuKeys(pathname)}
      mode="inline"
      theme="dark"
      items={items}
      inlineCollapsed={!isSideNavExpanded}
    />,
  ];
};

export default Navigation;
