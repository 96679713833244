/* global ANNOUNCEKIT_WHATSNEW_WIDGET_ID, ANNOUNCEKIT_SUGGEST_FEATURE_WIDGET_ID */
import {
  DownOutlined,
  LogoutOutlined,
  ExclamationCircleOutlined,
  MenuOutlined,
} from '@ant-design/icons';
import { useQueryClient } from '@tanstack/react-query';
import AnnounceKit from 'announcekit-react';
import {
  Breadcrumb as AntBreadcrumb,
  Button,
  Dropdown,
  Layout as AntLayout,
  PageHeader as AntPageHeader,
  Modal,
  Space,
  Row,
} from 'antd';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory, generatePath, useParams } from 'react-router-dom';
import { createSelector } from 'reselect';
import styled from 'styled-components';
import { t } from 'ttag';

import Loading from 'common/components/Loading';
import {
  SIDE_NAV_PROPERTY,
  SIDE_NAV_WIDTH,
  SideNavExpandedContext,
} from 'common/hooks/useSideNavExpanded';
import { getName as getEventName } from 'common/state/event';
import { isEnabled as isFlagEnabled } from 'common/state/flags';
import { getName } from 'common/state/organization';
import { getIsStaff, getUser, setUser } from 'common/state/user';

import AppAlerts from '@components/AppAlerts';
import HelpDropdown from '@components/HelpDropdown';
import UploadingDrawer, {
  useUploadingDrawer,
  STATUS_COMPLETED,
  STATUS_FAILED,
} from '@components/UploadingDrawer';
import { logout } from '@domains/Auth/service';
import PreviewButton from '@domains/Event/Preview';
import {
  TAB_ADMIN,
  TAB_APPS,
  TAB_EVENTS,
  TAB_INTEGRATIONS,
  TAB_ORGANIZERS,
} from '@domains/Organization/Details';
import useOrganization from '@domains/Organization/useOrganization';
import usePermission, {
  READ_API_KEYS,
  READ_EVENTS,
  READ_MULTI_EVENT_APPS,
  READ_ORGANIZERS,
  UPDATE_ORGANIZATION,
} from '@hooks/usePermission';
import LogoImage from '@images/em-logos/EM-logo-colour-dark.svg';
import SeparatorImage from '@images/illustrations/breadcrumb-separator.svg';

const { Header: AntHeader, Content: AntContent } = AntLayout;

const PageLayout = styled(AntLayout)`
  height: 100%;
`;

const PageHeader = styled(AntPageHeader)`
  padding: 0 5px;
  .ant-page-header-heading {
    height: 48px;
    align-items: center;
    flex-wrap: nowrap;
  }
  .ant-page-header-content {
    padding-top: 0;
  }
`;

const Header = styled(AntHeader)`
  background: #fff;
  border-bottom: 1px solid #e8e8e8;
  height: auto;
  padding: 0;
  width: 100%;
`;

const ContentLayout = styled(AntContent)`
  flex-shrink: 1;
`;

const Content = styled(AntContent)`
  height: 100%;
  background: #fff;

  ${props =>
    props.$fullWidth
      ? `
    display: block;
    overflow: auto;
  `
      : `
    display: flex;
    flex-direction: row;
    overflow: hidden;
  `}
`;

const Breadcrumb = styled(AntBreadcrumb)`
  ol {
    flex-wrap: nowrap;
  }
  & li {
    display: flex;
    align-items: center;
    flex-shrink: 0;
  }
`;

const BreadcrumbLink = styled(Breadcrumb.Item)`
  cursor: pointer;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.85);
`;

const Logo = styled.img`
  float: left;
  width: 108px;
  height: 48px;
`;

const WhatsNewWidget = styled(AnnounceKit)`
  padding: 0 4px;
  font-size: 16px;
  font-weight: 600;
`;

const selector = createSelector(
  getName,
  getEventName,
  getIsStaff,
  getUser,
  (organizationName, eventName, isStaff, { id, firstName, lastName, email }) => ({
    organizationName,
    eventName,
    userId: id,
    firstName,
    lastName,
    email,
    isStaff,
    displayName: firstName || lastName ? `${firstName} ${lastName}` : email,
  })
);

const ORGANIZATION_LIST_ROUTE = '/organization';
const SETTINGS_ROUTE = '/settings/profile';
const MANAGEMOBI_ROUTE = '/admin/masterlist/search';

const UserSettings = ({ displayName, isStaff }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { items } = useUploadingDrawer();
  const queryClient = useQueryClient();

  const onLogout = async () => {
    async function handleLogout() {
      try {
        await logout();
        dispatch(setUser(null));
        history.replace('/');
      } catch {
        // do nothing
      } finally {
        queryClient.clear();
      }
    }

    if (items.every(next => [STATUS_COMPLETED, STATUS_FAILED].includes(next.status))) {
      handleLogout();
      return;
    }

    Modal.confirm({
      title: t`Are you sure to log out?`,
      icon: <ExclamationCircleOutlined />,
      content: t`Changes you made will not be saved.`,
      onOk: handleLogout,
    });
  };
  const userMenuItems = [
    {
      key: SETTINGS_ROUTE,
      label: <Link to={SETTINGS_ROUTE}>{t`Settings`}</Link>,
    },
    isStaff && {
      key: MANAGEMOBI_ROUTE,
      label: <Link to={MANAGEMOBI_ROUTE}>{t`ManageMobi`}</Link>,
    },
    {
      type: 'divider',
      style: { marginTop: 0, marginBottom: 0 },
    },
    {
      key: 'logout',
      onClick: onLogout,
      icon: <LogoutOutlined />,
      label: t`Log out`,
    },
  ].filter(item => !!item);

  return (
    <Dropdown trigger={['click']} menu={{ items: userMenuItems }}>
      <Button type="text">
        {displayName} <DownOutlined />
      </Button>
    </Dropdown>
  );
};

function Layout({ children }) {
  const [isSideNavExpanded, setSideNavExpanded] = useState(true);
  const { eventId, organizationId } = useParams();
  const {
    organizationName,
    eventName,
    userId,
    firstName,
    lastName,
    email,
    displayName,
    isStaff,
  } = useSelector(selector);
  const { authorized, authorizedAny } = usePermission();
  const isProductModularizationEnabled = useSelector(state =>
    isFlagEnabled(state, 'is_product_modularization_enabled')
  );
  const isDashboardEnabled = useSelector(state => isFlagEnabled(state, 'is_dashboard_enabled'));

  const { loading } = useOrganization(organizationId);

  const makeUrl = target =>
    generatePath(`/organization/:organizationId/${target}`, { organizationId });

  if (loading) {
    return <Loading />;
  }

  return (
    <PageLayout
      // Setting a CSS variable so anything within this tree has dynamic access
      style={{
        [SIDE_NAV_PROPERTY]: isSideNavExpanded ? SIDE_NAV_WIDTH.Expanded : SIDE_NAV_WIDTH.Collapsed,
      }}
    >
      <Header>
        <PageHeader
          extra={
            <Space size="small">
              <WhatsNewWidget
                lang="auto"
                widget={`https://updates.eventmobi.com/widgets/v2/${ANNOUNCEKIT_WHATSNEW_WIDGET_ID}`}
                user={{
                  id: userId,
                  name: displayName,
                  email,
                }}
                widgetStyle={{
                  margin: '0 0 4px 4px',
                  fontSize: '16px',
                }}
              >
                {t`What's New?`}
              </WhatsNewWidget>
              <HelpDropdown firstName={firstName} lastName={lastName} email={email} />
              <UserSettings displayName={displayName} isStaff={isStaff} />
            </Space>
          }
          title={
            <Row align="middle">
              {isProductModularizationEnabled && !!eventId && (
                <Button
                  style={{ border: 'none', marginInline: 8 }}
                  onClick={() => setSideNavExpanded(prev => !prev)}
                  icon={<MenuOutlined />}
                />
              )}
              <Breadcrumb separator={<img src={SeparatorImage} alt="separator" />}>
                <Breadcrumb.Item>
                  <Link to={ORGANIZATION_LIST_ROUTE}>
                    <Logo src={LogoImage} />
                  </Link>
                </Breadcrumb.Item>
                {organizationId && (
                  <BreadcrumbLink
                    menu={{
                      items: [
                        authorized(READ_EVENTS) && {
                          key: TAB_EVENTS,
                          label: <Link to={makeUrl(TAB_EVENTS)}>{t`Events`}</Link>,
                        },
                        authorized(READ_ORGANIZERS) && {
                          key: TAB_ORGANIZERS,
                          label: <Link to={makeUrl(TAB_ORGANIZERS)}>{t`Organizers`}</Link>,
                        },
                        authorized(READ_API_KEYS) && {
                          key: TAB_INTEGRATIONS,
                          label: <Link to={makeUrl(TAB_INTEGRATIONS)}>{t`Integrations`}</Link>,
                        },
                        authorized(READ_MULTI_EVENT_APPS) && {
                          key: TAB_APPS,
                          label: <Link to={makeUrl(TAB_APPS)}>{t`Apps`}</Link>,
                        },
                        authorizedAny(UPDATE_ORGANIZATION) && {
                          key: TAB_ADMIN,
                          label: <Link to={makeUrl(TAB_ADMIN)}>{t`Admin`}</Link>,
                        },
                        {
                          type: 'divider',
                          style: { marginTop: 0, marginBottom: 0 },
                        },
                        {
                          key: ORGANIZATION_LIST_ROUTE,
                          label: (
                            <Link to={ORGANIZATION_LIST_ROUTE}>{t`Switch Organizations`}</Link>
                          ),
                        },
                      ].filter(Boolean),
                    }}
                    dropdownProps={{ trigger: ['click'] }}
                  >
                    {organizationName}
                  </BreadcrumbLink>
                )}
                {eventId && <Breadcrumb.Item>{eventName}</Breadcrumb.Item>}
              </Breadcrumb>
              {isDashboardEnabled && eventId && <PreviewButton />}
            </Row>
          }
        />
        <AppAlerts />
      </Header>
      <ContentLayout>
        <Content $fullWidth={!window.location.pathname.includes('/event/')}>
          <SideNavExpandedContext.Provider value={isSideNavExpanded}>
            {children}
          </SideNavExpandedContext.Provider>
        </Content>
        <UploadingDrawer />
      </ContentLayout>
      <AnnounceKit
        lang="auto"
        widget={`https://updates.eventmobi.com/widgets/v2/${ANNOUNCEKIT_SUGGEST_FEATURE_WIDGET_ID}`}
      />
    </PageLayout>
  );
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

UserSettings.propTypes = {
  displayName: PropTypes.string,
  isStaff: PropTypes.bool,
};

export default Layout;
