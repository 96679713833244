import React from 'react';
import { Route, Switch } from 'react-router-dom';

import CaptureBadges from '@domains/Badges/Capture/PDF/Badges';
import { CaptureLabelPDF } from '@domains/Badges/Capture/PDF/Labels';

const CapturePDF = () => (
  <Switch>
    <Route path="/event/:eventId/pdf-capture/badges/simple_label" exact>
      <CaptureLabelPDF />
    </Route>
    <Route
      path={[
        // This first route can be removed once the FF is enabled for all users.
        // Confirm with foundation and then remove with EXP-19211
        '/event/:eventId/pdf-capture/badges/:resourceId',
        '/event/:eventId/pdf-capture/badges/print_by_yourself/:resourceId',
        '/event/:eventId/pdf-capture/badges/pre_print/:resourceId',
      ]}
      exact
    >
      <CaptureBadges />
    </Route>
    <Route path="*">Unknown resource type</Route>
  </Switch>
);

export default CapturePDF;
