import {
  Alert,
  Button,
  Form,
  Input,
  message,
  Modal as AntModal,
  Space,
  Table as AntTable,
  Typography,
} from 'antd';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { t } from 'ttag';

import Label from 'common/components/Label';
import Loading from 'common/components/Loading';

import CopyButton from '@components/CopyButton';
import DomainStatusTag, { EXPIRED, FAILED, PENDING, TIMED_OUT } from '@components/DomainStatusTag';

import { INVALID_PUBLIC_DOMAIN_FAILURE_REASON, useCreateCustomDomainMutation } from './queries';
import useGetVerifyCustomDomain from './useGetVerifyCustomDomain';

const { Item, useForm } = Form;
const { Text, Link } = Typography;

const KNOWLEDGE_BASE_LINK =
  'https://help.eventmobi.com/en/knowledge/can-i-change-my-app-address-or-use-a-custom-domain';

/**
 * Formated DNS records and instructions that can be copied to the clipboard as both plain and
 * formatted text. This aims for a readable table regardless of where it is pasted by using
 * both <pre> tags and markdown code blocks (```)
 */
const getTextForCopyingInstructions = dnsRecords => {
  if (!dnsRecords.length) {
    return null;
  }

  const recordsWithHeader = [{ type: t`Type`, host: t`Host`, value: t`Value` }, ...dnsRecords];

  /**
   * Calculate the width of the columns to pad the values in the table. We don't need to pad the
   * last column, so we don't calculate its length
   */
  const colWidths = recordsWithHeader.reduce(
    (acc, next) => [Math.max(acc[0], next.type.length), Math.max(acc[1], next.host.length)],
    [0, 0]
  );

  const formattedRecords = recordsWithHeader
    .map(({ type, host, value }) =>
      [type.toUpperCase().padEnd(colWidths[0]), host.padEnd(colWidths[1]), value].join('   ')
    )
    .join('\n');

  const blobs = ['text/html', 'text/plain'].reduce((acc, type) => {
    // We use a breakTag variable in the translations since we need to render a <br /> in HTML
    // but just a linebreak (\n) in plain text, and the \n is part of the template string already
    const breakTag = type === 'text/html' ? '<br />' : '';
    const wrappedFormattedRecords =
      type === 'text/html'
        ? `<pre style="font: monospaced">${formattedRecords}</pre>` // HTML format
        : `\`\`\`\n${formattedRecords}\n\`\`\``; // Markdown format
    const content = t`\
Please utilize the provided records to configure your CNAME within your DNS provider.${breakTag}
${breakTag}
On your domain provider’s website, log in to your account, find the DNS settings or domain management area, update or add the following records:${breakTag}
${breakTag}
Records:${breakTag}
${wrappedFormattedRecords}`;
    const footer =
      type === 'text/html'
        ? t`If you need help to set up CNAME Records, you can learn more about <a href=${KNOWLEDGE_BASE_LINK}>Custom Domain</a> or reach out to <a href="mailto:support@eventmobi.com">support@eventmobi.com</a>.`
        : t`If you need help to set up CNAME Records, you can learn more about Custom Domain at ${KNOWLEDGE_BASE_LINK} or reach out to support@eventmobi.com.`;

    const fullText = `${content}\n\n${breakTag}${footer}`;
    return { ...acc, [type]: new Blob([fullText], { type }) };
  }, {});

  return new ClipboardItem(blobs);
};

export const CUSTOM_DOMAIN_EXISTS_ERROR = 'custom_domain_already_exists';
const ERROR_MESSAGES_BY_CODE = () => ({
  custom_domain_invalid: t`Invalid subdomain provided.`,
  custom_domain_limit_reached: t`Custom domain limit reached or exceeded.`,
  custom_domain_active_contract_required: t`An active contract is required to create a Custom Domain.`,
});

// Turn off row highlighting on hover, and set the host/value columns to a darker background
const Table = styled(AntTable)`
  tbody tr:hover td {
    background: none !important;
  }
  tbody .darkBackground,
  tbody tr:hover .darkBackground {
    background: #fafafa !important;
  }
`;

const InlineContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const WrappedText = styled.span`
  word-break: break-word;
  max-width: 85%;
`;

const Modal = ({ organizationId, onClose, id = null }) => {
  const [createdDomainId, setCreatedDomainId] = useState('');
  const [existingDomain, setExistingDomain] = useState('');
  const [dnsRecords, setDnsRecords] = useState([]);
  const [formInvalid, setFormInvalid] = useState(true);
  const { mutate: createCustomDomain, isLoading: isCreating } = useCreateCustomDomainMutation();
  const [regenerateMode, setRegenerateMode] = useState(false);
  const [refreshMode, setRefreshMode] = useState(false);
  const { data, isLoading, isRefetching, isRefetchError } = useGetVerifyCustomDomain(
    organizationId,
    id ?? createdDomainId,
    Boolean(id),
    regenerateMode,
    refreshMode
  );

  const isDnsRecordsExpired = useMemo(() => dnsRecords.every(record => record.status === EXPIRED), [
    dnsRecords,
  ]);
  const isValidationTimedOut = data?.data?.verificationStatus === TIMED_OUT;

  // if user creates a new custom domain but closes the modal before dns_records are fetched
  const isExistingDomainPendingDnsRecords =
    id && !dnsRecords.length > 0 && data?.data?.verificationStatus === PENDING;

  const textForCopyingInstructions = useMemo(() => getTextForCopyingInstructions(dnsRecords), [
    dnsRecords,
  ]);

  const [form] = useForm();

  useEffect(() => {
    if (data?.data?.dnsRecords) {
      setDnsRecords(data.data.dnsRecords);
    }

    if (regenerateMode && data && data?.data.verificationStatus !== TIMED_OUT) {
      setRegenerateMode(false);
    }
  }, [data, regenerateMode]);

  useEffect(() => {
    if (refreshMode && !isRefetching) {
      setRefreshMode(false);
      if (isRefetchError) {
        message.error(t`Status Refresh Failed`);
      } else {
        message.success(t`Status Refresh Successful`);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshMode, isRefetching]);

  const handleSave = async () => {
    try {
      const payload = await form.validateFields();

      createCustomDomain(
        { organizationId, payload },
        {
          onSuccess: res => {
            setCreatedDomainId(res.data.id);
          },
          onError: err => {
            if (err?.errors?.[0]?.code === CUSTOM_DOMAIN_EXISTS_ERROR) {
              setExistingDomain(payload.customDomain);
              form.validateFields(['customDomain']);
            } else {
              const errorMessage =
                ERROR_MESSAGES_BY_CODE()[err?.errors?.[0]?.code] ?? t`An unknown error occurred`;
              message.error(errorMessage);
            }
          },
        }
      );
    } catch (err) {
      if (err?.errorFields) return;
      message.error(t`An unknown error occurred`);
    }
  };

  return (
    <AntModal
      title={id || dnsRecords.length ? t`View Records` : t`Add Custom Domain`}
      open
      maskClosable={false}
      destroyOnClose
      onCancel={onClose}
      footer={
        <>
          <Button onClick={onClose}>{t`Close`}</Button>
          {/* eslint-disable-next-line no-nested-ternary */}
          {id ? (
            <>
              {((!isDnsRecordsExpired && !isValidationTimedOut && !regenerateMode) ||
                isExistingDomainPendingDnsRecords) && (
                <Button loading={isRefetching} onClick={() => setRefreshMode(true)} type="primary">
                  {t`Refresh Status`}
                </Button>
              )}
              {isValidationTimedOut && (
                <Button
                  disabled={regenerateMode}
                  onClick={() => setRegenerateMode(true)}
                  type="primary"
                >
                  {t`Regenerate CNAME`}
                </Button>
              )}
            </>
          ) : !createdDomainId ? (
            <Button disabled={formInvalid} loading={isCreating} onClick={handleSave} type="primary">
              {t`Next`}
            </Button>
          ) : null}
        </>
      }
      width={1000}
    >
      {isLoading ? (
        <Loading />
      ) : (
        <Space size="middle" direction="vertical" style={{ width: '100%' }}>
          {id ? (
            <Space.Compact direction="vertical" style={{ width: '100%' }}>
              <Label>{t`Custom Domain`}</Label>
              <Input disabled value={data?.data?.domainName} />
            </Space.Compact>
          ) : (
            <Form
              form={form}
              layout="vertical"
              requiredMark={false}
              onFieldsChange={() => {
                setFormInvalid(form.getFieldsError().some(item => item.errors.length > 0));
              }}
            >
              <Item
                label={
                  <div>
                    <Label>{t`Custom Domain`}</Label>
                    {!dnsRecords.length > 0 && (
                      <Text type="secondary">
                        {t`Enter the sub domain you want to connect to your events.`}
                      </Text>
                    )}
                  </div>
                }
                style={{ marginBottom: 0 }}
                name="customDomain"
                rules={[
                  {
                    required: true,
                    message: t`Only subdomains are allowed. Please enter a valid subdomain (e.g., event.eventmobi.com)`,
                    pattern: /^(?!localhost)(?=.*\.[A-Za-z]{2,}$)(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?\.){2,}[A-Za-z]{2,}$/,
                  },
                  {
                    validator(_, value) {
                      if (existingDomain && value === existingDomain) {
                        setFormInvalid(true);
                        return Promise.reject(
                          t`This subdomain already exists. Please use different domain or delete the existing domain and try again.`
                        );
                      }
                      return Promise.resolve();
                    },
                  },
                ]}
              >
                <Input disabled={!!createdDomainId} placeholder="example.yourdomain.com" />
              </Item>
            </Form>
          )}
          {dnsRecords.length > 0 && (
            <>
              <Typography>
                {t`CNAME Records have been generated. Please configure these records on your domain provider’s website. We recommend you to reach out to your IT department for assistance.  Once verified please do not remove these records.`}
              </Typography>
              <CopyButton
                toCopy={textForCopyingInstructions}
              >{t`Copy records & set up instructions`}</CopyButton>
              <Table
                rowKey="value"
                columns={[
                  {
                    title: t`Status`,
                    dataIndex: 'status',
                    render: status => <DomainStatusTag status={status} />,
                  },
                  {
                    title: t`Type`,
                    dataIndex: 'type',
                    render: type => type.toUpperCase(),
                  },
                  {
                    title: t`Name`,
                    dataIndex: 'host',
                    className: 'darkBackground',
                    render: host => (
                      <InlineContainer>
                        <WrappedText>{host}</WrappedText>
                        <CopyButton toCopy={host} />
                      </InlineContainer>
                    ),
                  },
                  {
                    title: t`Value`,
                    dataIndex: 'value',
                    className: 'darkBackground',
                    render: value => (
                      <InlineContainer>
                        <WrappedText>{value}</WrappedText>
                        <CopyButton toCopy={value} />
                      </InlineContainer>
                    ),
                  },
                ]}
                dataSource={dnsRecords}
                pagination={false}
              />
            </>
          )}
          <Text>
            {t`Need Help? `}
            <Link
              href={KNOWLEDGE_BASE_LINK}
              target="_blank"
            >{t`Learn more about Custom Domain.`}</Link>
          </Text>
          {((createdDomainId &&
            !dnsRecords.length > 0 &&
            data?.data?.verificationStatus !== FAILED) ||
            isExistingDomainPendingDnsRecords ||
            regenerateMode) && (
            <Alert
              type="warning"
              showIcon
              message={t`Generating your CNAME records might take a few minutes. You can safely close this modal.`}
            />
          )}
          {data?.data?.failureReason === INVALID_PUBLIC_DOMAIN_FAILURE_REASON && (
            <Alert
              type="error"
              showIcon
              message={t`CNAME records cannot be generated because the domain provided is invalid`}
            />
          )}
          {createdDomainId && dnsRecords.length > 0 && (
            <Alert
              type="info"
              showIcon
              message={
                <>
                  {t`Connect your custom domain following provided instructions. Configuration must be completed within `}
                  <Text strong>{t`72 hours`}</Text>.
                </>
              }
            />
          )}
        </Space>
      )}
    </AntModal>
  );
};

Modal.propTypes = {
  organizationId: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  id: PropTypes.string,
};

export default Modal;
