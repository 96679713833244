import { Typography, Space, Modal, Alert } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import { t } from 'ttag';

const { Text } = Typography;

const sections = [
  {
    name: 'Products',
    configs: ['is_video_library_enabled'],
  },
  {
    name: 'Event App Features',
    configs: ['is_a2a_messaging_enabled'],
  },
  {
    name: 'Video Experience',
    configs: [
      'rtmp_streaming',
      'native_livestream',
      'interactive_sessions',
      'session_experience_enable_pre_recorded_video_url',
      'session_experience_enable_video_conference_url',
    ],
  },
];
const privateChatConfigs = ['is_appointments_enabled', 'appointment_booking_video'];

const VirtualEventModal = ({ config, onCancel, onOk }) => {
  const renderSubConfigs = () => (
    <ul>
      {privateChatConfigs.map(configKey => (
        <li key={configKey}>{config[configKey].name}</li>
      ))}
    </ul>
  );

  const renderConfigs = section => (
    <ul>
      {section.configs.map(configKey => (
        <li key={configKey}>
          {config[configKey].name}
          {configKey === 'is_a2a_messaging_enabled' && renderSubConfigs()}
        </li>
      ))}
    </ul>
  );

  return (
    <Modal
      open
      title="Virtual Event Configs"
      onCancel={onCancel}
      onOk={onOk}
      okText={t`Yes, Continue`}
      cancelText={t`No, Cancel`}
    >
      <Space direction="vertical">
        <Space direction="vertical">
          <Alert message={t`Please remember to save your changes`} type="info" showIcon />
          <Text>{t`The Virtual Event Config Quick Action will turn on the following features`}</Text>
        </Space>

        {sections.map(section => (
          <Space key={section.name} direction="vertical">
            <Text strong>{section.name}</Text>
            {renderConfigs(section)}
          </Space>
        ))}
      </Space>
    </Modal>
  );
};

VirtualEventModal.propTypes = {
  config: PropTypes.objectOf(
    PropTypes.shape({
      type: PropTypes.oneOf(['boolean', 'integer', 'text', 'radio']).isRequired,
      name: PropTypes.string.isRequired,
      subConfigKeys: PropTypes.arrayOf(PropTypes.string),
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool, PropTypes.number]),
      canExpire: PropTypes.bool,
      maxExpiryInDays: PropTypes.number,
      expiry: PropTypes.object,
    })
  ).isRequired,
  onCancel: PropTypes.func.isRequired,
  onOk: PropTypes.func.isRequired,
};

export default VirtualEventModal;
