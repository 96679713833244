import apiFn from 'common/services/api-service';

const api = token => {
  let header = { Accept: 'application/vnd.eventmobi+json; version=p.5' };
  const withCredentials = !token;

  if (token) {
    header = { ...header, Authorization: `Basic ${token}` };
  }

  return apiFn('/api/uapi/badge-templates', header, withCredentials);
};

export function get(id, token) {
  return api(token)
    .get(id)
    .then(response => response.data.data);
}

export function list(sizes, unit, onlyPublished = true, include = []) {
  const params = {};

  if (sizes && unit && sizes.length > 0) {
    params.sizes = sizes.map(size => `${size.width}x${size.height}`).join(',');
    params.unitType = unit;
  }

  if (onlyPublished > 0) {
    params.isPublished = true;
  }

  if (include.length > 0) {
    params.include = include.join(',');
  }

  return api()
    .get(null, { params })
    .then(response => response.data.data);
}
